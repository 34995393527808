import { LockOpen, NavigateBefore, NavigateNext, Schedule } from '@mui/icons-material';
import { MobileDatePicker, MobileTimePicker } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  clockClear,
  clockIn,
  clockOut,
  clockRemarks,
  clockSelectors,
  clockServiceType,
  findRecords,
  loadRemarks,
  nextDate,
  nextEndTime,
  nextMonth,
  nextStartTime,
  prevDate,
  prevEndTime,
  prevMonth,
  prevStartTime,
  resetEndTime,
  resetStartTime,
  ServiceTypes,
  setEndLock,
  setEndTime,
  setRemarks,
  setServiceType,
  setStartLock,
  setStartTime,
  setTargetDate,
  VacationDayType,
  VacationType,
} from '../../app/clockSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { signout } from '../../app/userSlice';
import { AppFooter } from '../../components/AppFooter';
import { ClocksPunchItem, User } from '../../generated';
import { InitTimecard } from './InitTimecard';
import { Loading } from './Loading';
import { TimeCalendar } from './TimeCalendar';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme => theme.spacing(12),
  },
  title: {
    marginLeft: theme => theme.spacing(2),
    marginRight: theme => theme.spacing(2),
  },
  time: {},
  timeButton: {
    // marginLeft: theme.spacing(2),
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

function Neps2Link() {
  const moveNeps2 = () => {
    window.open('https://neps2.ndc-net.co.jp/neps2/');
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} style={{ marginTop: 16 }}>
        各種届出、承認等はNEPS2から行ってください。
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={moveNeps2}
          sx={{
            width: '120px',
          }}>
          NEPS2表示
        </Button>
      </Grid>
    </Grid>
  );
}

// export const ServiceTypes: { [id: string]: string } = {
//   '-1': '',
//   '1': '電車遅延',
//   '2': '早出出勤',
//   '3': '育児/介護時短',
//   '4': '時差勤務',
//   '5': '変則勤務',
//   '6': '宿直勤務',
//   '9': '自社勤務',
// };

export function TimecardPage() {
  const dispatch = useAppDispatch();
  const maxDate = useAppSelector(state => state.clock.maxDate);
  const targetDate = useAppSelector(state => state.clock.targetDate);
  const remarks = useAppSelector(state => state.clock.remarks);
  const created = useAppSelector(state => state.clock.created);
  // const [targetDate, setTargetDate] = useState(dayjs().startOf('day'));
  // const [remarks, setRemarks] = useState('');
  const startTime = useAppSelector(state => state.clock.startTime);
  const startLock = useAppSelector(state => state.clock.startLock);
  // const [startTime, setStartTime] = useState<dayjs.Dayjs>(dayjs());
  // const [startLock, setStartLock] = useState(false);
  const endTime = useAppSelector(state => state.clock.endTime);
  const endLock = useAppSelector(state => state.clock.endLock);
  // const [endTime, setEndTime] = useState<dayjs.Dayjs>(dayjs());
  // const [endLock, setEndLock] = useState(false);
  const record = useAppSelector(state => clockSelectors.selectById(state, targetDate.format('YYYY-MM-DD')));
  const remarksList = useAppSelector(state => state.clock.remarksList);
  const serviceType = useAppSelector(state => state.clock.serviceType);

  const loadingClear = useAppSelector(state => state.clock.loadClear);
  const loadingStart = useAppSelector(state => state.clock.loadStart);
  const loadingEnd = useAppSelector(state => state.clock.loadEnd);
  const loadingRemarks = useAppSelector(state => state.clock.loadRemarks);
  const loadingServiceType = useAppSelector(state => state.clock.loadServiceType);

  const [remarksSelect, setRemarksSelect] = useState('');

  // const [records, setRecords] = useState<ClocksPunchItem[]>([]);
  // const [superVisor, setSuperVisor] = useState(false);
  // const [record, setRecord] = useState<ClocksPunchItem>();
  // const [message, setMessage] = useState<{ type: TypeOptions; message: string }>();
  // const [remarksList, setRemarksList] = useState<string[]>([]);
  // const [serviceType, setServiceType] = useState<number>(-1);
  // const [agreement36Time, setAgreemtn36Time] = useState<number>(0);
  // const [requiredWork, setRequiredWork] = useState<boolean>(true);

  // const [loadClear, setLoadClear] = useState(false);
  // const [loadStart, setLoadStart] = useState(false);
  // const [loadEnd, setLoadEnd] = useState(false);
  // const [loadRemarks, setLoadRemarks] = useState(false);
  // const [loadServiceType, setLoadServiceType] = useState(false);

  // const setState = (selected: ClocksPunchItem | undefined) => {
  //   setRecord(selected);
  //   setRemarks((selected && selected.remarks) || '');
  //   setServiceType((selected && selected.n2_flag) || -1);

  //   const today = dayjs().startOf('day');
  //   if (today.isBefore(targetDate) || today.isSame(targetDate)) {
  //     setStartLock(false);
  //     setEndLock(false);
  //   } else {
  //     if (selected && selected.start_time) {
  //       setStartLock(true);
  //     } else {
  //       setStartLock(false);
  //     }
  //     if (selected && selected.end_time) {
  //       setEndLock(true);
  //     } else {
  //       setEndLock(false);
  //     }
  //   }
  // };

  useEffect(() => {
    dispatch(findRecords())
      .then(() => {
        dispatch(loadRemarks());
      })
      .catch(() => {
        dispatch(signout());
      });
  }, []);

  // 日付選択時、明細から選択された日付に該当する勤怠情報を取得
  useEffect(() => {
    let umount = false;

    (async () => {
      // if (records.length > 0) {
      //   if (dayjs(records[0].punch_date, 'YYYY-MM-DD').get('month') !== targetDate.get('month')) {
      //     await dispatch(findRecords());
      //     if (umount) return;
      //     await dispatch(loadRemarks());
      //   }
      // } else if (records.length === 0) {
      await dispatch(findRecords());
      if (umount) return;
      await dispatch(loadRemarks());
      if (umount) return;
      // }
    })();

    return () => {
      umount = true;
    };
  }, [targetDate]);

  useEffect(() => {
    const today = dayjs().startOf('day');
    if (today.isBefore(targetDate) || today.isSame(targetDate)) {
      dispatch(setStartLock(false));
      dispatch(setEndLock(false));
    } else {
      if (record && record.start_time) {
        dispatch(setStartLock(true));
      } else {
        dispatch(setStartLock(false));
      }
      if (record && record.end_time) {
        dispatch(setEndLock(true));
      } else {
        dispatch(setEndLock(false));
      }
    }
  }, [targetDate, record]);

  /**
   * 出勤登録
   */
  const clickClockIn = () => {
    dispatch(clockIn());
  };

  /**
   * 退勤登録
   */
  const clickClockOut = () => {
    dispatch(clockOut());
  };

  const clickClear = () => {
    dispatch(clockClear());
  };

  /**
   * 備考登録
   */
  const addRemarks = () => {
    dispatch(clockRemarks())
      .unwrap()
      .then(remarks => {
        setRemarksSelect(remarks);
      });
  };

  /**
   * 勤務フラグ登録
   */
  const addFlag2 = () => {
    dispatch(clockServiceType());
  };

  const clickPrevMonth = () => {
    dispatch(prevMonth());
    // setTargetDate(d => d.add(-1, 'month'));
  };
  const clickPrevDay = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(prevDate());
    // setTargetDate(d => d.add(-1, 'day'));
    event.preventDefault();
    event.stopPropagation();
  };
  const clickNextMonth = () => {
    dispatch(nextMonth());
    // setTargetDate(d => d.add(1, 'month'));
  };
  const clickNextDay = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setTargetDate(d => d.add(1, 'day'));
    dispatch(nextDate());
    event.preventDefault();
    event.stopPropagation();
  };

  const clickPrevStartTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(prevStartTime());
    // setStartTime(d =>
    //   d.minute() % 5 === 0 ? d.add(-5, 'minute') : d.add(-1 * (d.minute() - 5 * Math.floor(d.minute() / 5)), 'minute'),
    // );
    event.preventDefault();
    event.stopPropagation();
  };

  const clickNextStartTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(nextStartTime());
    // setStartTime(d =>
    //   d.minute() % 5 === 0 ? d.add(5, 'minute') : d.add(5 * (Math.floor(d.minute() / 5) + 1) - d.minute(), 'minute'),
    // );
    event.preventDefault();
    event.stopPropagation();
  };

  const clickResetStartTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetStartTime());
    // if (!record || !record.prescribed_starting_time) return;
    // const time = record.prescribed_starting_time.split(':');
    // const hour = Number(time[0]);
    // const minute = Number(time[1]);
    // setStartTime(d => d.set('hour', hour).set('minute', minute));
  };

  const clickPrevEndTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(prevEndTime());
    // setEndTime(d =>
    //   d.minute() % 5 === 0 ? d.add(-5, 'minute') : d.add(-1 * (d.minute() - 5 * Math.floor(d.minute() / 5)), 'minute'),
    // );
    event.preventDefault();
    event.stopPropagation();
  };

  const clickNextEndTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(nextEndTime());
    // setEndTime(d =>
    //   d.minute() % 5 === 0 ? d.add(5, 'minute') : d.add(5 * (Math.floor(d.minute() / 5) + 1) - d.minute(), 'minute'),
    // );
    event.preventDefault();
    event.stopPropagation();
  };

  const clickResetEndTime = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(resetEndTime());
    // if (!record || !record.prescribed_finishing_time) return;
    // const time = record.prescribed_finishing_time.split(':');
    // const hour = Number(time[0]);
    // const minute = Number(time[1]);
    // setEndTime(d => d.set('hour', hour).set('minute', minute));
  };

  const changeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRemarks(event.target.value));
    // setRemarks(event.target.value);
  };

  const changeServiceType = (event: SelectChangeEvent<number>) => {
    dispatch(setServiceType(Number(event.target.value)));
    // setServiceType(Number(event.target.value));
  };

  const isOneDayHoliday = (record: ClocksPunchItem | undefined) => {
    if (!record) return false;

    const flag = record.n1_flag || record.n1_vacation_type;

    if (!flag) return false;
    if (!VacationType[flag]) return false;
    if (VacationType[flag].dayType === VacationDayType.ONE_DAY) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          {created && (
            <>
              <div style={{ textAlign: 'center', marginTop: 16, marginBottom: 16 }}>
                <Typography variant="h6">勤怠登録</Typography>
              </div>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={8}>
                  <MobileDatePicker
                    label="勤怠日付"
                    InputProps={{
                      startAdornment: (
                        <IconButton color="primary" size="small" aria-label="prev-day" onClick={clickPrevDay}>
                          <NavigateBefore />
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          color="primary"
                          size="small"
                          aria-label="next-day"
                          onClick={clickNextDay}
                          disabled={targetDate.startOf('day').isSame(maxDate.startOf('day'))}>
                          <NavigateNext />
                        </IconButton>
                      ),
                      sx: { pl: 0, pr: 0 },
                    }}
                    maxDate={maxDate}
                    value={targetDate}
                    views={['day']}
                    showToolbar={false}
                    disableCloseOnSelect={false}
                    renderInput={params => <TextField size="small" helperText={null} {...params} />}
                    inputFormat="YYYY年M月D日(ddd)"
                    onChange={d => {
                      dispatch(setTargetDate(d));
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      (record && !record.start_time && !record.end_time) || loadingClear || isOneDayHoliday(record)
                    }
                    sx={sxProps.timeButton}
                    onClick={clickClear}>
                    {loadingClear ? <Loading /> : 'クリア'}
                  </Button>
                </Grid>

                <Grid item xs={8}>
                  <MobileTimePicker
                    renderInput={params => <TextField size="small" helperText={null} {...params} />}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          disabled={startLock || isOneDayHoliday(record)}
                          color="primary"
                          size="small"
                          aria-label="prev-day"
                          onClick={clickPrevStartTime}>
                          <NavigateBefore />
                        </IconButton>
                      ),
                      endAdornment: (
                        <>
                          {!isOneDayHoliday(record) && startLock && (
                            <IconButton color="primary" size="small" onClick={() => dispatch(setStartLock(false))}>
                              <LockOpen />
                            </IconButton>
                          )}
                          {!isOneDayHoliday(record) && !startLock && record && record.prescribed_starting_time && (
                            <IconButton
                              color="primary"
                              disabled={startLock}
                              size="small"
                              aria-label="next-day"
                              onClick={clickResetStartTime}>
                              <Schedule />
                            </IconButton>
                          )}

                          <IconButton
                            disabled={startLock || isOneDayHoliday(record)}
                            color="primary"
                            size="small"
                            aria-label="next-day"
                            onClick={clickNextStartTime}>
                            <NavigateNext />
                          </IconButton>
                        </>
                      ),
                      sx: { pl: 0, pr: 0 },
                    }}
                    label={record && record.start_time ? `出勤時刻 ${record.start_time} 登録済` : '出勤時刻'}
                    ampm={false}
                    disableCloseOnSelect={false}
                    disabled={startLock || isOneDayHoliday(record)}
                    minutesStep={5}
                    value={startTime}
                    inputFormat="HH:mm"
                    onChange={d => {
                      dispatch(setStartTime(d));
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    disabled={startLock || !startTime || loadingStart || isOneDayHoliday(record)}
                    color="primary"
                    fullWidth
                    sx={sxProps.timeButton}
                    onClick={clickClockIn}>
                    {loadingStart ? <Loading /> : '出勤'}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <MobileTimePicker
                    renderInput={params => <TextField size="small" helperText={null} {...params} />}
                    InputProps={{
                      startAdornment: (
                        <IconButton
                          disabled={endLock || isOneDayHoliday(record) || (record && !record.start_time)}
                          color="primary"
                          size="small"
                          aria-label="prev-day"
                          onClick={clickPrevEndTime}>
                          <NavigateBefore />
                        </IconButton>
                      ),
                      endAdornment: (
                        <>
                          {!isOneDayHoliday(record) && endLock && record && !!record.start_time && (
                            <IconButton color="primary" size="small" onClick={() => dispatch(setEndLock(false))}>
                              <LockOpen />
                            </IconButton>
                          )}
                          {!isOneDayHoliday(record) &&
                            !endLock &&
                            record &&
                            !!record.start_time &&
                            record.prescribed_finishing_time && (
                              <IconButton
                                disabled={endLock || isOneDayHoliday(record)}
                                color="primary"
                                size="small"
                                aria-label="next-day"
                                onClick={clickResetEndTime}>
                                <Schedule />
                              </IconButton>
                            )}

                          <IconButton
                            disabled={endLock || isOneDayHoliday(record) || (record && !record.start_time)}
                            color="primary"
                            size="small"
                            aria-label="next-day"
                            onClick={clickNextEndTime}>
                            <NavigateNext />
                          </IconButton>
                        </>
                      ),
                      sx: { pl: 0, pr: 0 },
                    }}
                    label={record && record.end_time ? `退勤時刻 ${record.end_time} 登録済` : '退勤時刻'}
                    disableCloseOnSelect={false}
                    ampm={false}
                    disabled={endLock || isOneDayHoliday(record) || (record && !record.start_time)}
                    minutesStep={5}
                    value={endTime}
                    inputFormat="HH:mm"
                    onChange={d => {
                      dispatch(setEndTime(d));
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      endLock || !endTime || loadingEnd || (record && !record.start_time) || isOneDayHoliday(record)
                    }
                    sx={sxProps.timeButton}
                    onClick={clickClockOut}>
                    {loadingEnd ? <Loading /> : '退勤'}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="remarks"
                    fullWidth
                    size="small"
                    label="備考"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={remarks}
                    onChange={changeRemarks}></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={sxProps.timeButton}
                    disabled={loadingRemarks}
                    onClick={addRemarks}>
                    {loadingRemarks ? <Loading /> : '備考登録'}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="remarks-select" shrink={true}>
                      過去の備考から選択
                    </InputLabel>
                    <Select<string>
                      label="過去の備考から選択"
                      notched
                      displayEmpty={true}
                      id="remarks-select"
                      value={remarksSelect}
                      disabled={remarksList.length === 0}
                      onChange={(event: SelectChangeEvent<string>) => {
                        dispatch(setRemarks(event.target.value));
                        setRemarksSelect(event.target.value);
                      }}>
                      <MenuItem value={''} style={{ minHeight: 36 }}></MenuItem>
                      {remarksList.map(remarks => (
                        <MenuItem key={remarks} value={remarks} style={{ minHeight: 36 }}>
                          {remarks}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="flag2" shrink={true}>
                      勤務フラグ
                    </InputLabel>
                    {record && (
                      <Select<number>
                        notched
                        label="勤務フラグ"
                        disabled={
                          (record && (record.day_type === 2 || record.day_type === 3)) || isOneDayHoliday(record)
                        }
                        id="flag2"
                        fullWidth
                        value={serviceType}
                        onChange={changeServiceType}>
                        {(record.service_types || []).map(id => (
                          <MenuItem key={id} value={id} style={{ minHeight: 36 }}>
                            {ServiceTypes[String(id)]}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    disabled={
                      loadingServiceType ||
                      (record && (record.day_type === 2 || record.day_type === 3)) ||
                      isOneDayHoliday(record)
                    }
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={sxProps.timeButton}
                    onClick={addFlag2}>
                    {loadingServiceType ? <Loading /> : 'フラグ登録'}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 32,
            }}>
            <IconButton color="primary" size="small" onClick={clickPrevMonth}>
              <NavigateBefore />
            </IconButton>
            <Typography variant="h6" sx={sxProps.title}>
              {`${targetDate.format('YYYY年M月')}の勤怠`}
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={clickNextMonth}
              disabled={targetDate.startOf('month').toISOString() >= dayjs().startOf('month').toISOString()}>
              <NavigateNext />
            </IconButton>
          </div>
          {!created && <InitTimecard></InitTimecard>}
          {created && <TimeCalendar></TimeCalendar>}

          <Neps2Link></Neps2Link>
        </Box>
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
}
