import { usersApi } from '../components/Api';
import { getUserId } from '../components/Cognito';

export class UserApi {
  public static async searchUser() {
    const api = await usersApi.usersUserIdGet(getUserId());
    const result = await api();
    return result.data;
  }

  // public static async showSignin() {
  //   try {
  //     await signin()

  //     // //初回ログイン時にオフライントークンをローカルストレージに保管する
  //     // if (keycloak.refreshTokenParsed) {
  //     //   const token = keycloak.refreshTokenParsed as { typ: string };
  //     //   if (token.typ === 'Offline') {
  //     //     localStorage.setItem('refleshToken', keycloak.refreshToken as string);
  //     //     localStorage.setItem('token', keycloak.token as string);
  //     //   }
  //     // }
  //   } catch (err) {
  //     Auth.signOut();
  //   }
  // }
}
