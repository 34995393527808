import { PayloadAction, createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  userId: string;
} = {
  userId: '',
};

// export const signin = createAsyncThunk<
//   User,
//   undefined,
//   {
//     state: RootState;
//     dispatch: AppDispatch;
//     rejectValue: string;
//   }
// >('user/signin', async (_, { rejectWithValue, dispatch }) => {
//   try {
//     const authenticated = await keycloak.init({
//       onLoad: 'check-sso',

//       // refreshToken: localRefleshToken,
//       // token: localToken,
//     });

//     if (authenticated) {
//       const user = await UserApi.searchUser();
//       return user;
//     } else {
//       return { ...initialState.user };
//     }
//   } catch (err) {
//     let message = 'ユーザ情報が取得できませんでした。もう一度実行してください。';
//     if (axios.isAxiosError(err)) {
//       if (err.response && err.response.data && isErrorInfo(err.response.data)) {
//         message = err.response.data.message;
//       }
//     }
//     console.error(err);
//     dispatch(
//       setMessage({
//         type: 'error',
//         message: message,
//       }),
//     );
//     return { ...initialState.user };
//   }
// });

// export const signout = createAsyncThunk<
//   void,
//   undefined,
//   {
//     state: RootState;
//     dispatch: AppDispatch;
//     rejectValue: string;
//   }
// >('user/signout', async (_, { dispatch }) => {
//   try {
//     await keycloak.logout();
//   } catch (err) {
//     console.error(err);
//     dispatch(setMessage({ type: 'error', message: 'サインアウトに失敗しました。' }));
//   }
// });

export const cognitoSlice = createSlice({
  name: 'cognito',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    signin: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    signout: state => {
      state.userId = '';
    },
    // setPenMode: (state, action)
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    // builder
    //   .addCase(signin.fulfilled, (state, action) => {
    //     state.user = action.payload;
    //     state.authenticated = !!action.payload.user_id;
    //   })
    //   .addCase(signout.fulfilled, state => {
    //     state.user = { ...initialState.user };
    //     state.authenticated = false;
    //   })
    //   .addMatcher(isRejected(signin, signout), state => {
    //     state.user = { ...initialState.user };
    //     state.authenticated = false;
    //   });
  },
});

// export const { signout } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectGroups = (state: RootState) => state.group.groups;
export const selectUserId = (state: RootState) => state.cognito.userId;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default cognitoSlice.reducer;
