import React from 'react';
import { Link, Typography } from '@mui/material';

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://www.ndc-net.co.jp/">
        {`© Nihon Data Control Co., Ltd. ${new Date().getFullYear()}.`}
      </Link>
    </Typography>
  );
};
