import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { ClocksApi, ClocksInitItem, ClocksPunchItem } from '../generated';
import { isErrorInfo } from './apiTypes';
import { ClockApi } from './clockApi';
import { setMessage } from './messageSlice';
import { AppDispatch, RootState } from './store';

export const ServiceTypes: { [id: string]: string } = {
  '-1': '',
  '1': '電車遅延',
  '2': '早出出勤',
  '3': '育児/介護時短',
  '4': '時差勤務',
  '5': '変則勤務',
  '6': '宿直勤務',
  '9': '自社勤務',
};

export enum VacationDayType {
  ONE_DAY,
  HALF_DAY_AM,
  HALF_DAY_PM,
}

export type Vacation = {
  name: string;
  dayType: VacationDayType;
};

export const VacationType: { [flag: number]: Vacation } = {
  1: { name: '有休', dayType: VacationDayType.ONE_DAY },
  2: { name: '午前有休', dayType: VacationDayType.HALF_DAY_AM },
  3: { name: '午後有休', dayType: VacationDayType.HALF_DAY_PM },
  11: { name: '代休', dayType: VacationDayType.ONE_DAY },
  12: { name: '午前半代休', dayType: VacationDayType.HALF_DAY_AM },
  13: { name: '午後半代休', dayType: VacationDayType.HALF_DAY_PM },
  21: { name: '特休(有給)', dayType: VacationDayType.ONE_DAY },
  22: { name: '午前半特休(有給)', dayType: VacationDayType.HALF_DAY_AM },
  23: { name: '午後半特休(有給)', dayType: VacationDayType.HALF_DAY_PM },
  24: { name: '特休(無給)', dayType: VacationDayType.ONE_DAY },
  25: { name: '午前半特休(無給)', dayType: VacationDayType.HALF_DAY_AM },
  26: { name: '午後半特休(無給)', dayType: VacationDayType.HALF_DAY_PM },
  27: { name: '子の看護休暇', dayType: VacationDayType.ONE_DAY },
  28: { name: '介護休暇', dayType: VacationDayType.ONE_DAY },
  34: { name: '子の看護休暇(午前)', dayType: VacationDayType.HALF_DAY_AM },
  35: { name: '子の看護休暇(午後)', dayType: VacationDayType.HALF_DAY_PM },
  36: { name: '介護休暇(午前)', dayType: VacationDayType.HALF_DAY_AM },
  37: { name: '介護休暇(午後)', dayType: VacationDayType.HALF_DAY_PM },
  31: { name: '欠勤', dayType: VacationDayType.ONE_DAY },
  32: { name: '午前欠勤', dayType: VacationDayType.HALF_DAY_AM },
  33: { name: '午後欠勤', dayType: VacationDayType.HALF_DAY_PM },
  70: { name: '生理休暇', dayType: VacationDayType.ONE_DAY },
  71: { name: '本人結婚(5日)', dayType: VacationDayType.ONE_DAY },
  72: { name: '配偶者出産(2日)', dayType: VacationDayType.ONE_DAY },
  73: { name: '産前・産後休暇', dayType: VacationDayType.ONE_DAY },
  74: { name: 'つわり休暇', dayType: VacationDayType.ONE_DAY },
  75: { name: '忌引(3日)', dayType: VacationDayType.ONE_DAY },
  76: { name: '忌引(2日)', dayType: VacationDayType.ONE_DAY },
  77: { name: '裁判員休暇', dayType: VacationDayType.ONE_DAY },
  1001: { name: 'am代休pm有休', dayType: VacationDayType.ONE_DAY },
  1002: { name: 'am有休pm代休', dayType: VacationDayType.ONE_DAY },
  10001: { name: '育児休業', dayType: VacationDayType.ONE_DAY },
  10002: { name: '介護休業', dayType: VacationDayType.ONE_DAY },
  10003: { name: '休職', dayType: VacationDayType.ONE_DAY },
  10004: { name: '退職', dayType: VacationDayType.ONE_DAY },
};

export const clockRecordAdapter = createEntityAdapter<ClocksPunchItem>({
  selectId: (punchItem: ClocksPunchItem) => punchItem.punch_date,
  // 作成日時について降順とする
  sortComparer: (a, b) => (a.punch_date < b.punch_date ? -1 : a.punch_date > b.punch_date ? 1 : 0),
});

const initialState = clockRecordAdapter.getInitialState({
  //選択可能最大日付
  maxDate: dayjs().endOf('month').startOf('day'),
  //選択日付
  targetDate: dayjs().startOf('day'),
  //備考
  remarks: '',
  //出勤時刻
  startTime: dayjs(),
  //退勤時刻
  endTime: dayjs(),
  //管理職
  superVisor: false,
  //過去の備考
  remarksList: [] as string[],
  //フラグ２
  serviceType: -1,
  //シフト
  shiftCode: 1,
  //36協定超過時間
  agreement36Time: 0,
  //作番選択必須
  requiredWork: true,
  //クリア中
  loadClear: false,
  //出勤時刻登録中
  loadStart: false,
  //退勤時刻登録中
  loadEnd: false,
  //備考登録中
  loadRemarks: false,
  //フラグ2登録中
  loadServiceType: false,
  //出勤時刻ロック
  startLock: false,
  //退勤事項ロック
  endLock: false,
  //勤怠初期化済み
  created: true,
  //初期化中
  loadInit: false,
});

/** データ取得非同期処理 */
export const init = createAsyncThunk<
  undefined,
  {
    targetDate: Dayjs;
    preset: boolean;
    userId: string;
    workingHoursId: number;
    sakubanCd: number;
    initItems?: ClocksInitItem[];
  },
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/init', async (params, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;

    await ClockApi.init(params);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `${targetDate.format('M')}月の勤怠を初期化しました。`,
      }),
    );
  } catch (err) {
    let message = '勤怠を初期化できませんでした。NEPS2で初期化してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );
    return rejectWithValue('勤怠を初期化できませんでした。NEPS2で初期化してください。');
  }
});

export const findRecords = createAsyncThunk<
  {
    records: ClocksPunchItem[];
    agreement36Time: number;
    requiredWork: boolean;
    created: boolean;
    superVisor: boolean;
    workingHoursId: number;
    targetDate: Dayjs;
  },
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/findRecords', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;

    const response = await ClockApi.searchRecords(targetDate);
    const agreement36Time = response.agreements36_excess_time_total || 0;
    const requiredWork = Boolean(response.required_work);
    const created = Boolean(response.created);
    const superVisor = Boolean(response.super_visor);
    const workingHoursId = response.working_hours_id || -1;

    return {
      records: response.punch_items || [],
      agreement36Time,
      requiredWork,
      created,
      superVisor,
      workingHoursId,
      targetDate,
    };
  } catch (err) {
    let message = '勤怠情報を取得できませんでした。時間をおいて、再度お試しください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );
    return rejectWithValue('勤怠情報を取得できませんでした。時間をおいて、再度お試しください。');
  }
});

export const loadRemarks = createAsyncThunk<
  {
    remarksList: string[];
  },
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/loadRemarks', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const records = state.clock.ids.map(id => clockSelectors.selectById(state, id));
    const targetDate = state.clock.targetDate;

    const beforeResponse = await ClockApi.searchRecords(targetDate.add(-1, 'month').startOf('day'));

    const remarksList: string[] = [];

    [...records, ...(beforeResponse.punch_items || [])].forEach(r => {
      if (r.remarks && remarksList.findIndex(remark => remark === r.remarks) < 0) {
        remarksList.push(r.remarks);
      }
    });

    return {
      remarksList,
    };
  } catch (err) {
    let message = '勤怠情報を取得できませんでした。時間をおいて、再度お試しください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を取得できませんでした。時間をおいて、再度お試しください。');
  }
});

export const clockIn = createAsyncThunk<
  undefined,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/clockIn', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const startTime = state.clock.startTime;

    const response = await ClockApi.clockIn(targetDate, startTime);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `出勤時間を${response.start_time}で登録しました。`,
      }),
    );
  } catch (err) {
    let message = '勤怠情報を登録できませんでした。NEPS2から登録してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を登録できませんでした。NEPS2から登録してください。');
  }
});

export const clockOut = createAsyncThunk<
  undefined,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/clockOut', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const endTime = state.clock.endTime;

    const response = await ClockApi.clockOut(targetDate, endTime);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `${response.start_time} - ${response.end_time}で登録しました。`,
      }),
    );
  } catch (err) {
    let message = '勤怠情報を登録できませんでした。NEPS2から登録してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を登録できませんでした。時間をおいて、再度お試しください。');
  }
});

export const clockClear = createAsyncThunk<
  undefined,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/clockClear', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;

    await ClockApi.clear(targetDate);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `${targetDate.format('YYYY-MM-DD')}の登録をクリアしました。`,
      }),
    );
  } catch (err) {
    let message = '勤怠情報を登録できませんでした。NEPS2から登録してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を登録できませんでした。時間をおいて、再度お試しください。');
  }
});

export const clockRemarks = createAsyncThunk<
  string,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/clockRemarks', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const remarks = state.clock.remarks;

    await ClockApi.addRemarks(targetDate, remarks);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `備考を登録しました。`,
      }),
    );
    return remarks;
  } catch (err) {
    let message = '勤怠情報を登録できませんでした。NEPS2から登録してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を登録できませんでした。時間をおいて、再度お試しください。');
  }
});

export const clockServiceType = createAsyncThunk<
  undefined,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('clock/clockServiceType', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const serviceType = state.clock.serviceType;

    await ClockApi.addServiceType(targetDate, serviceType);
    await dispatch(findRecords());
    dispatch(
      setMessage({
        type: 'success',
        message: `勤務フラグを登録しました。`,
      }),
    );
  } catch (err) {
    let message = '勤怠情報を登録できませんでした。NEPS2から登録してください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );

    return rejectWithValue('勤怠情報を登録できませんでした。時間をおいて、再度お試しください。');
  }
});

export type LoadType = 'clear' | 'start' | 'end' | 'remarks' | 'sericeType';

export const clockSlice = createSlice({
  name: 'clock',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStartLock: (state, action: PayloadAction<boolean>) => {
      state.startLock = action.payload;
    },
    setEndLock: (state, action: PayloadAction<boolean>) => {
      state.endLock = action.payload;
    },
    setTargetDate: (state, action: PayloadAction<Dayjs>) => {
      state.targetDate = action.payload.startOf('day');
      const selectRecord = state.entities[state.targetDate.format('YYYY-MM-DD')];
      if (selectRecord) {
        state.startTime = selectRecord.start_time ? dayjs(selectRecord.start_time, 'HH:mm') : dayjs();
        state.endTime = selectRecord.end_time ? dayjs(selectRecord.end_time, 'HH:mm') : dayjs();
        state.remarks = selectRecord.remarks || '';
        state.serviceType = selectRecord.n2_flag || -1;
        state.shiftCode = selectRecord.shift_code || 1;
      } else {
        state.startTime = dayjs();
        state.endTime = dayjs();
        state.remarks = '';
        state.serviceType = -1;
        state.shiftCode = 1;
      }
    },
    nextDate: state => {
      state.targetDate = state.targetDate.add(1, 'day').startOf('day');
    },
    prevDate: state => {
      state.targetDate = state.targetDate.add(-1, 'day').startOf('day');
    },
    nextMonth: state => {
      state.targetDate = state.targetDate.add(1, 'month').startOf('month').startOf('day');
    },
    prevMonth: state => {
      state.targetDate = state.targetDate.add(-1, 'month').startOf('month').startOf('day');
    },
    setRemarks: (state, action: PayloadAction<string>) => {
      state.remarks = action.payload;
    },
    setStartTime: (state, action: PayloadAction<Dayjs>) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action: PayloadAction<Dayjs>) => {
      state.endTime = action.payload;
    },
    setServiceType: (state, action: PayloadAction<number>) => {
      state.serviceType = action.payload;
    },
    setShiftCode: (state, action: PayloadAction<number>) => {
      state.shiftCode = action.payload;
    },
    prevStartTime: state => {
      state.startTime =
        state.startTime.minute() % 5 === 0
          ? state.startTime.add(-5, 'minute')
          : state.startTime.add(
              -1 * (state.startTime.minute() - 5 * Math.floor(state.startTime.minute() / 5)),
              'minute',
            );
    },
    nextStartTime: state => {
      state.startTime =
        state.startTime.minute() % 5 === 0
          ? state.startTime.add(5, 'minute')
          : state.startTime.add(
              5 * (Math.floor(state.startTime.minute() / 5) + 1) - state.startTime.minute(),
              'minute',
            );
    },
    resetStartTime: state => {
      const record = state.entities[state.targetDate.format('YYYY-MM-DD')]; //clockSelectors.selectById(state, state.targetDate.format('YYYY-MM-DD'))

      if (!record || !record.prescribed_starting_time) return;
      const time = record.prescribed_starting_time.split(':');
      const hour = Number(time[0]);
      const minute = Number(time[1]);
      state.startTime = state.startTime.set('hour', hour).set('minute', minute);
    },
    prevEndTime: state => {
      state.endTime =
        state.endTime.minute() % 5 === 0
          ? state.endTime.add(-5, 'minute')
          : state.endTime.add(-1 * (state.endTime.minute() - 5 * Math.floor(state.endTime.minute() / 5)), 'minute');
    },
    nextEndTime: state => {
      state.endTime =
        state.endTime.minute() % 5 === 0
          ? state.endTime.add(5, 'minute')
          : state.endTime.add(5 * (Math.floor(state.endTime.minute() / 5) + 1) - state.endTime.minute(), 'minute');
    },
    resetEndTime: state => {
      const record = state.entities[state.targetDate.format('YYYY-MM-DD')]; //clockSelectors.selectById(state, state.targetDate.format('YYYY-MM-DD'))

      if (!record || !record.prescribed_finishing_time) return;
      const time = record.prescribed_finishing_time.split(':');
      const hour = Number(time[0]);
      const minute = Number(time[1]);
      state.endTime = state.endTime.set('hour', hour).set('minute', minute);
    },
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(findRecords.fulfilled, (state, action) => {
        if (!state.targetDate.isSame(action.payload.targetDate)) {
          return;
        }

        clockRecordAdapter.setAll(state, action.payload.records);
        state.agreement36Time = action.payload.agreement36Time;
        state.requiredWork = action.payload.requiredWork;
        state.created = action.payload.created;
        state.superVisor = action.payload.superVisor;

        const selectRecord = state.entities[state.targetDate.format('YYYY-MM-DD')];
        if (selectRecord) {
          state.startTime = selectRecord.start_time ? dayjs(selectRecord.start_time, 'HH:mm') : dayjs();
          state.endTime = selectRecord.end_time ? dayjs(selectRecord.end_time, 'HH:mm') : dayjs();
          state.remarks = selectRecord.remarks || '';
          state.serviceType = selectRecord.n2_flag || -1;
          state.shiftCode = selectRecord.shift_code || 1;
        } else {
          state.startTime = dayjs();
          state.endTime = dayjs();
          state.remarks = '';
          state.serviceType = -1;
          state.shiftCode = 1;
        }
      })
      .addCase(loadRemarks.fulfilled, (state, action) => {
        state.remarksList = action.payload.remarksList;
      })
      .addCase(init.pending, state => {
        state.loadInit = true;
      })
      .addCase(init.fulfilled, state => {
        state.loadInit = false;
      })
      .addCase(init.rejected, state => {
        state.loadInit = false;
      })
      .addCase(clockIn.pending, state => {
        state.loadStart = true;
      })
      .addCase(clockIn.fulfilled, state => {
        state.loadStart = false;
      })
      .addCase(clockIn.rejected, state => {
        state.loadStart = false;
      })
      .addCase(clockOut.pending, state => {
        state.loadEnd = true;
      })
      .addCase(clockOut.fulfilled, state => {
        state.loadEnd = false;
      })
      .addCase(clockOut.rejected, state => {
        state.loadEnd = false;
      })
      .addCase(clockClear.pending, state => {
        state.loadClear = true;
      })
      .addCase(clockClear.fulfilled, state => {
        state.loadClear = false;
      })
      .addCase(clockClear.rejected, state => {
        state.loadClear = false;
      })
      .addCase(clockRemarks.pending, state => {
        state.loadRemarks = true;
      })
      .addCase(clockRemarks.fulfilled, (state, action) => {
        state.loadRemarks = false;
        const remarks = action.payload;
        if (remarks) {
          if (state.remarksList.findIndex(r => r === remarks) < 0) {
            state.remarksList.push(remarks);
          }
        }
      })
      .addCase(clockRemarks.rejected, state => {
        state.loadRemarks = false;
      })
      .addCase(clockServiceType.pending, state => {
        state.loadServiceType = true;
      })
      .addCase(clockServiceType.fulfilled, state => {
        state.loadServiceType = false;
      })
      .addCase(clockServiceType.rejected, state => {
        state.loadServiceType = false;
      })
      .addMatcher(isRejected(findRecords, loadRemarks), (state, action) => {
        //nop
      });
  },
});

export const {
  setStartLock,
  setEndLock,
  setTargetDate,
  nextDate,
  prevDate,
  nextMonth,
  prevMonth,
  setRemarks,
  setStartTime,
  setEndTime,
  setServiceType,
  setShiftCode,
  prevStartTime,
  nextStartTime,
  resetStartTime,
  prevEndTime,
  nextEndTime,
  resetEndTime,
} = clockSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const clockSelectors = clockRecordAdapter.getSelectors<RootState>(state => state.clock);
// export const selectRecords = (state: RootState) => clockSelectors.selectAll(state);
// export const selectMaxDate = (state: RootState) => state.clock.maxDate;
// export const selectTargetDate = (state: RootState) => state.clock.targetDate;
// export const selectStartTime  = (state: RootState) => state.clock.startTime;
// export const selectEndTime  = (state: RootState) => state.clock.endTime;
// export const selectRemarks  = (state: RootState) => state.clock.remarks;
// export const selectServiceType  = (state: RootState) => state.clock.serviceType;
// export const selectShiftCode  = (state: RootState) => state.clock.shiftCode;
// export const selectStartLock =  (state: RootState) => state.clock.startLock;
// export const selectEndLock =  (state: RootState) => state.clock.endLock;
// export const selectCreated =  (state: RootState) => state.clock.created;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default clockSlice.reducer;
