import { cognitoStore } from './CognitoStore';

export const setUserId = (userId: string) => {
  cognitoStore.getState().setUserId(userId);
};

export const getUserId = () => {
  return cognitoStore.getState().userId;
};

// export const getAttributes = async (cognitoUser: CognitoUser): Promise<{ [key: string]: string }> => {
//   return new Promise((resolve, reject) => {
//     cognitoUser.getUserAttributes((err, attrs) => {
//       if (err) {
//         reject(err);
//         return;
//       }
//       if (!attrs) {
//         resolve({});
//         return;
//       }
//       const result: { [key: string]: string } = {};
//       attrs.map(attr => (result[attr.Name] = attr.Value));
//       resolve(result);
//     });
//   });
// };
