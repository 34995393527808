import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import usePwa from 'use-pwa';
import { promptEvent, installed, setInstalled } from './PwaInstaller';

export const UpdateHeader = () => {
  const { isPwa, enabledUpdate, unregister } = usePwa();

  const handleClick = useCallback(() => {
    (async () => {
      await unregister();
      window.location.reload();
    })();
  }, [unregister]);

  return (
    <>
      {isPwa && enabledUpdate && (
        <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
          新しいバージョンへ更新する
        </Button>
      )}
    </>
  );
};
