import { ExitToApp, SystemUpdate } from '@mui/icons-material';
import { Avatar, Box, Button, Container, CssBaseline, SxProps, Theme, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import usePwa from 'use-pwa';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser, signout } from '../../app/userSlice';
import { AppFooter } from '../../components/AppFooter';
import { Copyright } from '../../components/Copyright';
import { User } from '../../generated';
import { AppVersion } from '../../versions';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme => theme.spacing(12),
  },
  title: {
    marginTop: theme => theme.spacing(4),
    marginBottom: theme => theme.spacing(2),
  },
  avatar: {
    margin: theme => theme.spacing(1),
    maxWidth: '80%',
    width: 512,
    height: 'auto',
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export function InfoPage() {
  const dispatch = useAppDispatch();
  const { isPwa, unregister } = usePwa();

  const clickLogout = () => {
    dispatch(signout());
  };

  const handleClick = useCallback(() => {
    (async () => {
      await unregister();
      window.location.reload();
    })();
  }, [unregister]);

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          <Typography variant="h5" sx={sxProps.title}>
            {`シン・NEPS2 ver.${AppVersion}`}
          </Typography>
          <Avatar alt="シン・NEPS2" src="icon-512x512.png" sx={sxProps.avatar} />
          <p>
            シン・NEPS2はDX研究開発事業部のロボット「福来郎」のために開発された、API認証基盤を活用した勤怠登録アプリです。
          </p>
          <Button
            style={{ marginTop: 24, marginBottom: 48 }}
            variant="contained"
            color="secondary"
            startIcon={<ExitToApp />}
            onClick={clickLogout}>
            ログアウト
          </Button>
          {isPwa && (
            <Box sx={sxProps.paper}>
              <Box>{`動作がおかしい場合、以下のボタンを押してください。アプリを強制的に更新します。`}</Box>
              <Box sx={{ mt: 2 }}>{`現在のバージョン${AppVersion}`}</Box>
              <Button variant="contained" color="primary" onClick={handleClick}>
                アプリ強制更新
              </Button>
            </Box>
          )}
          <Copyright></Copyright>
        </Box>
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
}
