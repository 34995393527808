import { AccessTime, CheckCircleOutline, HelpOutline, InfoOutlined, Login } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { isAuthenticated, selectUser } from '../app/userSlice';

export const AppFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const authenticated = useAppSelector(isAuthenticated);

  return (
    <BottomNavigation
      value={location.pathname}
      // value={value}
      // onChange={(event, newValue) => {
      //     setValue(newValue);
      // }}
      showLabels>
      {authenticated && (
        <BottomNavigationAction value="/" label="勤怠登録" icon={<AccessTime />} onClick={() => navigate('/')} />
      )}
      {authenticated && (user.group_leader || user.team_leader || user.team_manager) && (
        <BottomNavigationAction
          value="/users"
          label="勤怠確認"
          icon={<CheckCircleOutline />}
          onClick={() => navigate('/users')}
        />
      )}
      {!authenticated && (
        <BottomNavigationAction value="/" label="サインイン" icon={<Login />} onClick={() => navigate('/')} />
      )}
      <BottomNavigationAction value="/help" label="ヘルプ" icon={<HelpOutline />} onClick={() => navigate('/help')} />
      {authenticated && (
        <BottomNavigationAction
          value="/info"
          label="アプリ情報"
          icon={<InfoOutlined />}
          onClick={() => navigate('/info')}
        />
      )}
    </BottomNavigation>
  );
};
