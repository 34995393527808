import { Backdrop, Box, CircularProgress, Container, CssBaseline, SxProps, Theme, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { registCard, selectCardLoading, selectCardStatus } from '../../app/cardSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AppFooter } from '../../components/AppFooter';
import React from 'react';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme => theme.spacing(12),
  },
  title: {
    marginTop: theme => theme.spacing(4),
    marginBottom: theme => theme.spacing(2),
  },
  backdrop: {
    zIndex: theme => theme.zIndex.drawer + 1,
    color: '#fff',
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function CardPage() {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectCardLoading);
  const status = useAppSelector(selectCardStatus);

  //初期表示処理
  useEffect(() => {
    dispatch(registCard(query.get('token') || ''));
  }, []);

  const Error = () => {
    return (
      <>
        <Typography variant="h5" sx={sxProps.title}>
          登録失敗
        </Typography>
        <p>カード登録用のURLが不正か期限切れのためカードが登録できませんでした。</p>
      </>
    );
  };

  const Success = () => {
    return (
      <>
        <Typography variant="h5" sx={sxProps.title}>
          登録完了
        </Typography>
        <p>カード登録が完了しました。</p>
      </>
    );
  };

  const Loading = () => {
    return (
      <Backdrop sx={sxProps.backdrop} open={status === ''}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          {loading || (status === '' && <Loading></Loading>)}
          {!loading && status === 'success' && <Success></Success>}
          {!loading && status && status !== 'success' && <Error></Error>}
        </Box>
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
}
