import { Dayjs } from 'dayjs';
import { clocksApi } from '../components/Api';
import { getUserId } from '../components/Cognito';
import { ClocksGetResponse, ClocksInitItem } from '../generated';

export class ClockApi {
  public static async searchRecords(targetDate: Dayjs): Promise<ClocksGetResponse> {
    const api = await clocksApi.clocksGet(undefined, getUserId(), undefined, targetDate.format('YYYY-MM'));
    const result = await api();
    return result.data;
  }

  public static async clockIn(targetDate: Dayjs, startTime: Dayjs) {
    const api = await clocksApi.clocksInPost({
      start_time: startTime.format('HH:mm'),
      user_id: getUserId(),
      punch_date: targetDate.format('YYYY-MM-DD'),
    });

    const result = await api();
    return result.data;
  }

  public static async clockOut(targetDate: Dayjs, endTime: Dayjs) {
    const api = await clocksApi.clocksOutPost({
      end_time: endTime.format('HH:mm'),
      user_id: getUserId(),
      punch_date: targetDate.format('YYYY-MM-DD'),
    });

    const result = await api();
    return result.data;
  }

  public static async clear(targetDate: Dayjs) {
    const api = await clocksApi.clocksClearPost({
      user_id: getUserId(),
      clear_date: targetDate.format('YYYY-MM-DD'),
    });

    const result = await api();
    return result.data;
  }

  public static async addRemarks(targetDate: Dayjs, remarks: string) {
    const api = await clocksApi.clocksRemarksPost({
      remarks: remarks,
      user_id: getUserId(),
      punch_date: targetDate.format('YYYY-MM-DD'),
    });
    const result = await api();
    return result.data;
  }

  public static async addServiceType(targetDate: Dayjs, serviceType: number) {
    const api = await clocksApi.clocksServiceTypePost({
      service_type: serviceType,
      user_id: getUserId(),
      punch_date: targetDate.format('YYYY-MM-DD'),
    });
    const result = await api();
    return result.data;
  }

  public static async init(params: {
    targetDate: Dayjs;
    preset: boolean;
    userId: string;
    workingHoursId: number;
    sakubanCd: number;
    initItems?: ClocksInitItem[];
  }) {
    const api = await clocksApi.clocksInitPost({
      preset: params.preset,
      punch_month: params.targetDate.format('YYYY-MM'),
      sakuban_code: params.sakubanCd,
      working_hours_id: params.workingHoursId,
      user_id: params.userId,
      init_items: params.initItems,
    });
    return await api();
  }
}
