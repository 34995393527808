import dayjs, { Dayjs } from 'dayjs';
import { clocksApi } from '../components/Api';
import { getUserId } from '../components/Cognito';
import { User } from '../generated';
import { SummaryClockTree } from './summarySlice';

export class SummaryApi {
  public static async searchRecords(targetDate: Dayjs) {
    const api = await clocksApi.clocksListGet(undefined, getUserId(), targetDate.format('YYYY-MM-DD'));
    const result = await api();
    return result.data;
  }

  public static async createSummaryTree(targetDate: Dayjs, user: User): Promise<SummaryClockTree> {
    const records = await SummaryApi.searchRecords(targetDate);

    const root: SummaryClockTree = {
      members: [],
      rowType: 'member',
      name: '',
      memberCount: 0,
    };

    const teamManagers: { [index: string]: SummaryClockTree } = {};
    const teamLeaders: { [index: string]: SummaryClockTree } = {};
    for (const record of records) {
      if (!record.employe_id) return root;
      if (record.manager_id) {
        if (!teamManagers[record.manager_id]) {
          teamManagers[record.manager_id] = {
            members: [],
            rowType: 'teamManager',
            name: record.manager_name || '',
            memberCount: 0,
          };
        }
      }

      if (record.team_id) {
        if (!teamLeaders[record.team_id]) {
          teamLeaders[record.team_id] = {
            members: [],
            rowType: 'teamLeader',
            name: record.team_name || '',
            memberCount: 0,
          };
          if (record.manager_id) {
            teamManagers[record.manager_id].members.push(teamLeaders[record.team_id]);
          }
        }
      }

      if (teamManagers[record.employe_id]) {
        teamManagers[record.employe_id].row = record;
      }
      if (record.team_id) {
        if (teamLeaders[record.team_id]) {
          teamLeaders[record.team_id].members.push({
            members: [],
            row: record,
            rowType: 'member',
            name: record.employe_name || '',
            memberCount: 0,
          });
          if (record.leader_id === record.employe_id) {
            teamLeaders[record.team_id].row = record;
          }
        }
      }
    }

    if (user.group_leader) {
      root.rowType = 'groupManager';
      root.members = Object.values(teamManagers).sort((team1, team2) => {
        if (!team1.row) {
          return -1;
        }
        if (!team2.row) {
          return -1;
        }
        if ((team1.row.employe_id || '') < (team2.row.employe_id || '')) {
          return -1;
        }
        if ((team1.row.employe_id || '') > (team2.row.employe_id || '')) {
          return 1;
        }
        return 0;
      });
    } else if (user.team_manager) {
      root.rowType = 'teamManager';
      root.members = Object.values(teamLeaders).sort((team1, team2) => {
        if (!team1.row) {
          return -1;
        }
        if (!team2.row) {
          return -1;
        }
        if ((team1.row.team_id || '') < (team2.row.team_id || '')) {
          return -1;
        }
        if ((team1.row.team_id || '') > (team2.row.team_id || '')) {
          return 1;
        }
        return 0;
      });
    } else if (user.team_leader) {
      root.rowType = 'teamLeader';
      if (Object.values(teamLeaders).length > 0) {
        root.members = Object.values(teamLeaders)[0].members;
        root.row = Object.values(teamLeaders)[0].row;
      } else {
        root.members = [];
      }
    }

    if (root.rowType !== 'member') {
      SummaryApi.aggregate(root);
    }

    return root;
  }

  private static aggregate(root: SummaryClockTree) {
    let max = 0;
    let latest: Dayjs | null = null;
    let old: Dayjs | null = null;
    let sum = 0;
    let memberCount = 0;

    if (root.rowType === 'teamLeader') {
      for (const member of root.members) {
        if (!member.row) continue;
        if (!member.row.punch_date) continue;
        const punchDate = dayjs(member.row.punch_date, 'YYYY-MM-DD');
        if (!latest) {
          latest = punchDate;
        } else if (latest.isBefore(punchDate)) {
          latest = punchDate;
        }

        if (!old) {
          old = punchDate;
        } else if (old.isAfter(punchDate)) {
          old = punchDate;
        }

        if (member.row.overtime_working_hours_total) {
          if (!max) {
            max = member.row.overtime_working_hours_total;
          } else if (max < member.row.overtime_working_hours_total) {
            max = member.row.overtime_working_hours_total;
          }
          sum += member.row.overtime_working_hours_total;
        }

        memberCount += 1;
      }
    } else if (root.rowType === 'teamManager' || root.rowType === 'groupManager') {
      for (const member of root.members) {
        SummaryApi.aggregate(member);

        if (member.latest) {
          const punchDate = dayjs(member.latest, 'YYYY-MM-DD');
          if (!latest) {
            latest = punchDate;
          } else if (latest.isBefore(punchDate)) {
            latest = punchDate;
          }
        }

        if (member.old) {
          const punchDate = dayjs(member.old, 'YYYY-MM-DD');
          if (!old) {
            old = punchDate;
          } else if (old.isAfter(punchDate)) {
            old = punchDate;
          }
        }

        if (member.maxTime) {
          if (!max) {
            max = member.maxTime;
          } else if (max < member.maxTime) {
            max = member.maxTime;
          }
        }
        sum += member.sumTime || 0;
        memberCount += member.memberCount;
      }
    }

    if (latest) {
      root.latest = latest.format('YYYY-MM-DD');
    }
    if (old) {
      root.old = old.format('YYYY-MM-DD');
    }
    root.maxTime = max;
    root.memberCount = memberCount;
    root.sumTime = sum;
    root.members;
  }
}
