import { cardsApi } from '../components/Api';

export class CardApi {
  public static async registCard(token: string) {
    const api = await cardsApi.cardsPost({
      token: token,
    });
    const result = await api();

    return result.status === 200 ? 'success' : 'error';
  }
}
