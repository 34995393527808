type PromptEvent = Event & { prompt: () => Promise<void>; userChoice: Promise<{ outcome: string }> };

export let promptEvent: PromptEvent | null = null;
export let installed = true;

const appInstalled = function () {
  promptEvent = null;
  installed = true;
};

const beforeinstallprompt = function (e: Event) {
  //   promptEvent = e;
  promptEvent = e as PromptEvent;
  e.preventDefault();
  installed = false;
  return false;
};

window.addEventListener('beforeinstallprompt', beforeinstallprompt);
window.addEventListener('appinstalled', appInstalled);

export const setInstalled = () => {
  installed = true;
  promptEvent = null;
};
// export const isInstalled = () => installed;
// export const canInstall = () => promptEvent && !installed;
