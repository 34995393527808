import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { clockSelectors, init } from '../../app/clockSlice';
import { departmentSelectors, findDepartments } from '../../app/departmentSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectInitItems, setRecords } from '../../app/initSlice';
import { findSakubans, sakubanSelectors } from '../../app/sakubanSlice';
import { selectUser } from '../../app/userSlice';
import { findWorkingHours, workingHourSelectors } from '../../app/workingHoursSlice';
import { Department } from '../../generated';
import { InitTimeCalendar } from './InitTimeCalender';
import { Loading } from './Loading';
import { ShiftTable } from './ShiftTable';

export function InitTimecard() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const targetDate = useAppSelector(state => state.clock.targetDate);
  const requiredWork = useAppSelector(state => state.clock.requiredWork);
  const sakubans = useAppSelector(state => sakubanSelectors.selectAll(state));
  const sakubanEntities = useAppSelector(state => state.sakuban.entities);
  const workingHours = useAppSelector(state => workingHourSelectors.selectAll(state));
  const workingHoursEntities = useAppSelector(state => state.workingHour.entities);
  const departments = useAppSelector(state => departmentSelectors.selectAll(state));
  const records = useAppSelector(state => clockSelectors.selectAll(state));
  const initItems = useAppSelector(selectInitItems);
  const loadingInit = useAppSelector(state => state.clock.loadInit);
  // const [sakubans, setSakubans] = useState<{ [sakubanCd: number]: Sakuban }>({});
  // const [sakubanIds, setSakubanIds] = useState<number[]>([]);
  // const [workingHours, setWorkingHours] = useState<{ [workingHourId: number]: WorkingHour }>({});
  // const [workingHourIds, setWorkingHourIds] = useState<number[]>([]);
  // const [departments, setDepartments] = useState<{ [departMentId: number]: Department }>({});
  // const [departmentIds, setDepartmentIds] = useState<number[]>([]);
  const [sakubanDepartments, setSakubanDepartments] = useState<Department[]>([]);
  const [workingHourDepartments, setWorkingHourDepartments] = useState<Department[]>([]);
  const [selectWorkingHourDepartment, setSelectWorkingHourDepartment] = useState<number>(0);
  const [selectSakubanDepartment, setSelectSakubanDepartment] = useState<number>(0);
  const [selectWorkingHour, setSelectWorkingHour] = useState<number | null>(null);
  const [selectSakubanCd, setSelectSakubanCd] = useState<number | null>(null);
  const [preset, setPreset] = useState<boolean>(true);

  useEffect(() => {
    let umount = false;

    (async () => {
      if (umount) return;
      await dispatch(findDepartments());
      if (umount) return;
      await dispatch(findSakubans());
      if (umount) return;
      await dispatch(findWorkingHours());
    })();

    return () => {
      umount = true;
    };
  }, [targetDate]);

  useEffect(() => {
    if (selectWorkingHour == null) return;
    const hour = workingHoursEntities[selectWorkingHour];
    if (!hour) return;
    dispatch(setRecords({ records, workingHour: hour }));
  }, [records, selectWorkingHour, workingHoursEntities]);

  useEffect(() => {
    const sakubanDepartments: Department[] = [];
    const departmentIds = departments.map(d => d.department_id);
    sakubans.forEach(s => {
      const idx = departmentIds.indexOf(s.department_id);
      if (idx >= 0) {
        if (sakubanDepartments.findIndex(sakuban => s.department_id === sakuban.department_id) < 0) {
          sakubanDepartments.push(departments[idx]);
        }
      }
    });

    sakubanDepartments.sort((a, b) => a.department_id - b.department_id);
    setSakubanDepartments(sakubanDepartments);
  }, [sakubans, departments]);

  useEffect(() => {
    const workingHourDepartments: Department[] = [];
    const departmentIds = departments.map(d => d.department_id);
    workingHours.forEach(h => {
      const idx = departmentIds.indexOf(h.department_id);
      if (idx >= 0) {
        if (workingHourDepartments.findIndex(hour => hour.department_id === h.department_id) < 0) {
          workingHourDepartments.push(departments[idx]);
        }
      }
    });
    workingHourDepartments.sort((a, b) => a.department_id - b.department_id);
    setWorkingHourDepartments(workingHourDepartments);
  }, [workingHours, departments]);

  useEffect(() => {
    if (user.default_sakuban_cd && selectSakubanCd == null) {
      const sakuban = sakubans.find(s => s.sakuban_cd === user.default_sakuban_cd);

      if (sakuban && sakubanDepartments.findIndex(sd => sd.department_id === sakuban.department_id) >= 0) {
        setSelectSakubanCd(sakuban.sakuban_cd);
        setSelectSakubanDepartment(sakuban.department_id);
      }
    }
  }, [user, sakubans, sakubanDepartments, selectSakubanCd]);

  useEffect(() => {
    if (user.default_working_hours_id && selectWorkingHour == null) {
      const workingHour = workingHours.find(h => h.working_hour_id === user.default_working_hours_id);
      if (workingHour && workingHourDepartments.findIndex(wh => wh.department_id === workingHour.department_id) >= 0) {
        setSelectWorkingHour(workingHour.working_hour_id);
        setSelectWorkingHourDepartment(workingHour.department_id);
      }
    }
  }, [user, workingHours, workingHourDepartments, selectWorkingHour]);

  const workDepartmentSelect = useCallback(
    (event: SelectChangeEvent<number>, child: React.ReactNode) => {
      if (selectWorkingHour != null) {
        const selectHour = workingHours.find(h => h.working_hour_id === selectWorkingHour);
        if (selectHour && selectHour.department_id !== event.target.value) {
          setSelectWorkingHour(-1);
        }
      }

      setSelectWorkingHourDepartment(event.target.value as number);
    },
    [selectWorkingHour, workingHours],
  );

  const sakubanDepartmentSelect = useCallback(
    (event: SelectChangeEvent<number>, child: React.ReactNode) => {
      if (selectSakubanCd != null) {
        const selectSakuban = sakubans.find(s => s.sakuban_cd === selectSakubanCd);
        if (selectSakuban && selectSakuban.department_id !== event.target.value) {
          setSelectSakubanCd(-1);
        }
      }

      setSelectSakubanDepartment(event.target.value as number);
    },
    [sakubans, selectSakubanCd],
  );

  const workSelect = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    const id = event.target.value as number;
    setSelectWorkingHour(id);
  };

  const sakubanSelect = (event: SelectChangeEvent<number>, child: React.ReactNode) => {
    const id = event.target.value as number;
    setSelectSakubanCd(id);
  };

  const presetSelect = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPreset(checked);
  };

  const clickInit = () => {
    dispatch(
      init({
        preset: preset,
        sakubanCd: selectSakubanCd || -1,
        workingHoursId: selectWorkingHour || -1,
        targetDate: targetDate,
        userId: user.user_id,
        initItems: initItems,
      }),
    );
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 16 }}>
      <Grid item xs={12}>
        <Typography variant="h6">勤怠を以下の内容で初期化します。</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">勤怠テーブル</Typography>
      </Grid>
      <Grid item xs={6}>
        <Select fullWidth value={selectWorkingHourDepartment} onChange={workDepartmentSelect} size="small">
          <MenuItem value={0} style={{ minHeight: 36 }}></MenuItem>
          {workingHourDepartments.map(d => (
            <MenuItem key={d.department_id} value={d.department_id}>
              {d.department_name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select fullWidth value={selectWorkingHour || -1} onChange={workSelect} size="small">
          <MenuItem value={-1} style={{ minHeight: 36 }}></MenuItem>
          {selectWorkingHourDepartment &&
            workingHours
              .filter(wh => wh.department_id === selectWorkingHourDepartment)
              .map(wh => (
                <MenuItem
                  key={wh.working_hour_id}
                  value={wh.working_hour_id}>{`${wh.working_hour_id}:${wh.working_hour_name}`}</MenuItem>
              ))}
          {!selectWorkingHourDepartment &&
            workingHours.map(wh => (
              <MenuItem
                key={wh.working_hour_id}
                value={wh.working_hour_id}>{`${wh.working_hour_id}:${wh.working_hour_name}`}</MenuItem>
            ))}
        </Select>
      </Grid>
      {requiredWork && (
        <>
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <Typography variant="h6">主たる作番</Typography>
          </Grid>
          <Grid item xs={6}>
            <Select fullWidth value={selectSakubanDepartment} onChange={sakubanDepartmentSelect} size="small">
              <MenuItem value={0} style={{ minHeight: 36 }}></MenuItem>
              {sakubanDepartments.map(d => (
                <MenuItem key={d.department_id} value={d.department_id}>
                  {d.department_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select fullWidth value={selectSakubanCd || -1} onChange={sakubanSelect} size="small">
              <MenuItem value={-1} style={{ minHeight: 36 }}></MenuItem>
              {selectSakubanDepartment &&
                sakubans
                  .filter(sa => sa.department_id === selectSakubanDepartment)
                  .map(sa => (
                    <MenuItem
                      key={sa.sakuban_cd}
                      value={sa.sakuban_cd}>{`${sa.sakuban_cd}:${sa.sakuban_name}`}</MenuItem>
                  ))}
              {!selectSakubanDepartment &&
                sakubans.map(sa => (
                  <MenuItem key={sa.sakuban_cd} value={sa.sakuban_cd}>{`${sa.sakuban_cd}:${sa.sakuban_name}`}</MenuItem>
                ))}
            </Select>
          </Grid>
        </>
      )}
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <Typography variant="h6">初期設定</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={preset} onChange={presetSelect} name="checkedB" color="primary" />}
          label="始業・終業時刻を定時で初期設定する"
        />
      </Grid>
      {selectWorkingHour != null && workingHoursEntities[selectWorkingHour] && (
        <>
          <Grid item xs={12}>
            {workingHoursEntities[selectWorkingHour]!.shiftCodes.map(code => (
              <ShiftTable
                key={code}
                shiftCode={code}
                shifts={workingHoursEntities[selectWorkingHour]!.shifts}
                name={
                  workingHoursEntities[selectWorkingHour]!.shiftCodes.length === 1
                    ? '就業時間'
                    : `シフト：${workingHoursEntities[selectWorkingHour]!.shifts[code].shift_name || ''}`
                }></ShiftTable>
            ))}
          </Grid>
          {workingHoursEntities[selectWorkingHour]!.shiftCodes.length > 1 && (
            <Grid item xs={12}>
              <InitTimeCalendar selectWorkingHourId={selectWorkingHour || -1}></InitTimeCalendar>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Button
            sx={{
              width: '120px',
            }}
            variant="contained"
            color="primary"
            disabled={
              loadingInit ||
              (requiredWork && !sakubanEntities[selectSakubanCd || -1]) ||
              !workingHoursEntities[selectWorkingHour || -1]
            }
            onClick={clickInit}>
            {loadingInit ? <Loading /> : `勤怠初期化`}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
