import { Dayjs } from 'dayjs';
import { masterApi } from '../components/Api';

export class DepartmentApi {
  public static async searchDepartments(targetDate: Dayjs) {
    const api = await masterApi.masterDepartmentGet(targetDate.format('YYYY-MM'));
    const result = await api();
    return result.data;
  }
}
