import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardMedia,
  Collapse,
  ListItemButton,
  ListItemText,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { orange, red } from '@mui/material/colors';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Shifts } from '../../app/workingHoursSlice';

const sxProps: { [name: string]: SxProps<Theme> } = {
  holiday: {
    backgroundColor: orange[200],
  },
  legalHoliday: {
    backgroundColor: red[200],
  },
  header: {
    padding: '8px 6px',
  },
  cell: {
    padding: '8px 6px',
    cursor: 'pointer',
  },
  day: {
    width: 12,
  },
  textCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0,
    textAlign: 'left',
  },
};

export function ShiftTable(props: { shiftCode: number; shifts: Shifts; name: string }) {
  const [open, setOpen] = useState(false);
  const shift = props.shifts[props.shiftCode];
  const base = dayjs('1900-01-01', 'YYYY-MM-DD').startOf('day');

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary={props.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  午前開始
                </TableCell>
                <TableCell align="center">{shift.start_am_time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  午前終了
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {shift.end_am_time}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  昼休
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {base.set('minute', shift.lunch_break_time || 0).format('H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  午後開始
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {shift.start_pm_time}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  午後終了
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {shift.end_pm_time}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  夕方休憩
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {base.set('minute', shift.at_dusk_break_time || 0).format('H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  残業開始
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {shift.overtime_start_time}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  実働時間
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {base.set('minute', shift.service_time || 0).format('H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  調整時間
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {base.set('minute', shift.adjust_time || 0).format('H:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={sxProps.header}>
                  残業計算開始
                </TableCell>
                <TableCell align="center" sx={sxProps.cell}>
                  {shift.overtime_count_start_time}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </>
  );
}
