import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeOptions } from 'react-toastify';
import { RootState } from './store';

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    type: 'success' as TypeOptions,
    message: '',
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMessage: (state, action: PayloadAction<{ type: TypeOptions; message: string }>) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    reset: state => {
      state.message = '';
    },
    // signout: state => {
    //   keycloak.logout().then(() => navigate('/'));
    //   state.user = null;
    //   state.authenticated = false;
    // },
    // setPenMode: (state, action)
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: builder => {
  //   builder
  //     .addCase(signin.fulfilled, (state, action) => {
  //       state.user = action.payload;
  //       state.authenticated = !!action.payload;
  //     })
  //     .addCase(signout.fulfilled, state => {
  //       state.user = { ...initialState.user };
  //       state.authenticated = false;
  //     })
  //     .addMatcher(isRejected(signin, signout), (state, action) => {
  //       state.error = action.payload || '';
  //     });
  // },
});

export const { reset, setMessage } = messageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectGroups = (state: RootState) => state.group.groups;
export const selectMessage = (state: RootState) => state.message;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default messageSlice.reducer;
