import { Auth } from 'aws-amplify';
import { Configuration } from '../generated';
import { CardsApiFp, ClocksApiFp, MasterApiFp, UsersApiFp } from '../generated/api';

const configuration = new Configuration({
  basePath: '/api/v1',
  accessToken: async () => {
    const credentials = await Auth.currentSession();
    const token = credentials.getIdToken().getJwtToken();
    return token || '';
  },
});

export const usersApi = UsersApiFp(configuration);
export const clocksApi = ClocksApiFp(configuration);
export const cardsApi = CardsApiFp(configuration);
export const masterApi = MasterApiFp(configuration);

// import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// export const get = async <T extends { [index: string]: string | number }, K>(
//   url: string,
//   param?: T,
// ): Promise<AxiosResponse<K>> => {
//   const config: AxiosRequestConfig = {
//     method: 'get',
//     url: url,
//     params: param,
//   };

//   return request(config);
// };

// export const post = async <T extends { [index: string]: string | number }, K>(
//   url: string,
//   param?: T,
// ): Promise<AxiosResponse<K>> => {
//   const config: AxiosRequestConfig = {
//     method: 'post',
//     url: '/api/v1' + url,
//     data: param,
//   };

//   return request(config);
// };

// const request = async <T extends { [index: string]: string | number }, K>(
//   config: AxiosRequestConfig,
// ): Promise<AxiosResponse<K>> => {
//   try {
//     await keycloak.updateToken(5);
//     const result = await axios(config);
//     return result as AxiosResponse<K>;
//   } catch (e) {
//     return {
//       status: 500,
//       data: e,
//       statusText: '',
//       headers: '',
//       config: config,
//     };
//   }
// };
