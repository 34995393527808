import { ExpandLess, ExpandMore, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Collapse,
  Container,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { AppFooter } from '../../components/AppFooter';
import { TimeCardTable } from '../SummaryPage/TimeCardTable';
import React, { useState } from 'react';
import { User } from '../../generated';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme => theme.spacing(1),
  },
  title: {
    marginTop: theme => theme.spacing(4),
    marginBottom: theme => theme.spacing(1),
  },
  avatar: {
    margin: theme => theme.spacing(1),
    maxWidth: '80%',
    width: 512,
    height: 'auto',
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

function HelpLogin() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="サインイン" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>サインイン画面</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                サインインボタンを押下すると、NEPS2と接続している認証システムの画面が表示されます。
              </Typography>
              <Card>
                <CardMedia component="img" image="/help/login/login_1.png" alt="ログイン画面" />
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>認証システム画面の表示</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                NEPS2にログインする際のIDとパスワードを入力してください。Remember
                meチェックボックスを設定すると、ログイン状態が維持されます。
              </Typography>
              <Card>
                <CardMedia component="img" image="/help/login/login_2.png" alt="認証画面" />
              </Card>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

function HelpKintaiInit() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="月初・勤怠の初期化" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>勤怠の初期化</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                NEPS2と同様、勤怠テーブル、作番を指定して月の勤怠表を初期登録します。始業、就業を定時で初期設定のチェックボックスを設定すると、1ヶ月分の勤怠を定時で初期設定します。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/init/init_1.png" alt="勤怠初期化" />
              </Card>
              <Typography>就業時間をタップすると、開始、終了の時間を確認できます。</Typography>
              <Card>
                <CardMedia component="img" image="/help/init/init_2.png" alt="就業時間" />
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>シフト勤務の場合</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>シフト勤務の場合、初期化時にシフトを設定してください。</Typography>
              <Card>
                <CardMedia component="img" image="/help/init/init_3.png" alt="シフト登録" />
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>変形労働時間制の場合</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>変形労働時間制の場合、シフトと休日を設定してください。</Typography>
              <Card>
                <CardMedia component="img" image="/help/init/init_4.png" alt="変形労働時間制" />
              </Card>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

function HelpKintaiTouroku() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="勤怠の登録" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>勤怠の登録</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                勤怠日付の項目から日付を選択するか、下の勤怠表から、登録したい日の行を選択することで、登録する日を選択できます。出勤ボタンで出勤時間を登録します。時計ボタンを押すと、定時時間に設定します。時間の左右の矢印は、5分刻みで時間を変更します。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/kintai/kintai_1.png" alt="出勤" />
              </Card>
              <Typography>出勤時間登録後、退勤ボタンで退勤時間を登録します。</Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/kintai/kintai_2.png" alt="退勤" />
              </Card>
              <Typography>
                備考、早出や自社勤務を設定するフラグの設定に対応しています。備考は当月、前月の入力内容から選択することも可能です。フラグを設定した場合、出勤・退勤の登録済み時刻はクリアされます。
              </Typography>
              <Card>
                <CardMedia component="img" image="/help/kintai/kintai_3.png" alt="備考" />
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>勤怠の修正</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                登録済み、過去の日付の勤怠は誤修正防止のため、ロックされています。鍵のボタンを押すことで、上書き登録できるようになります。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/kintai/kintai_4.png" alt="出勤" />
              </Card>
              <Typography>鍵ボタン押下後、上書き登録する形で時間を修正して登録できます。</Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/kintai/kintai_5.png" alt="退勤" />
              </Card>
              <Typography>クリアボタンを押すと、出勤、退勤の登録済み時間を削除します。</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

function HelpTL() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="TL向け勤怠の登録確認" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>勤怠登録確認機能</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>TLは指定した月のチームメンバの勤怠の最新登録日、残業時間を確認できます。</Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/tl/tl_1.png" alt="TL機能" />
              </Card>
              <Typography>
                また営業は担当するチーム毎の登録状況、残業の状況、部門長は部門内社員の登録状況、残業状況が確認できるようになっています。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/tl/tl_2.png" alt="営業機能" />
              </Card>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

function HelpInstall() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="インストール方法" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Androidの場合</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Androidの場合はシンNEPS2にログイン後、画面上部にホーム画面に追加ボタンが表示されます。押下するとインストールされ、ホームにアイコンが追加されます。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_1.png" alt="Android1" />
              </Card>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_2.png" alt="Android2" />
              </Card>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_3.png" alt="Android3" />
              </Card>
              <Typography>
                ホーム追加ボタンが表示されない場合、Chromeのメニューからホーム画面に追加を選択してインストールしてください。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_4.png" alt="Android3" />
              </Card>
              <Typography>
                試行版を使用しており、アプリを起動しても画面が最新版に更新されない場合、アプリのアイコン長押しから、サイトの設定を選択し、キャッシュを削除してください。chromeの閲覧履歴を削除しても更新が行われます。（今回だけの対応となります。）
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_5.png" alt="Android3" />
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>iOSの場合</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                iOSの場合はSafariのメニューから、ホーム画面に追加を選択してインストールしてください。
              </Typography>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_6.png" alt="iOS1" />
              </Card>
              <Card sx={{ mb: 1 }}>
                <CardMedia component="img" image="/help/install/install_7.png" alt="iOS2" />
              </Card>
              <Typography>
                iOS15にはバグがあり、認証画面表示時に入力項目をタッチしてもキーボードが表示されない場合があります。その時は、他の画面から何か文字をコピーして、ログインIDなどにペーストを行ってください。キーボードが表示されます。
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

function HelpFirst() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(open => !open)}>
        <ListItemText primary="アプリでできること、NEPS2で行うこと" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ p: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>アプリ内で実現できること</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>アプリでは以下の機能に対応しています。</Typography>
              <Card sx={{ mb: 1 }}>
                <ul>
                  <li>勤怠の初期化（シフト勤務、変形労働時間にも対応）</li>
                  <li>日々の出勤・退勤時刻の登録・修正登録</li>
                  <li>勤怠への備考の登録</li>
                  <li>早出・時差出勤・本社勤務などの勤務フラグの登録</li>
                </ul>
              </Card>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>NEPS2で行うこと</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>次のことはNEPS2で行ってください。</Typography>
              <Card sx={{ mb: 1 }}>
                <ul>
                  <li>休暇等の届け出の登録</li>
                  <li>勤怠の承認依頼</li>
                  <li>各種支払請求の登録</li>
                </ul>
              </Card>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Collapse>
    </>
  );
}

export function HelpPage(): JSX.Element {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    HelpKintai: false,
    HelpLogin: false,
  });

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          <Typography variant="h5" sx={sxProps.title}>
            シンNEPS2の使い方
          </Typography>
        </Box>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
          <HelpFirst></HelpFirst>
          <HelpLogin></HelpLogin>
          <HelpInstall></HelpInstall>
          <HelpKintaiInit></HelpKintaiInit>
          <HelpKintaiTouroku></HelpKintaiTouroku>
          <HelpTL></HelpTL>
        </List>
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
}
