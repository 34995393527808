import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { setUserId } from '../components/Cognito';
import { User } from '../generated';
import { setMessage } from './messageSlice';
import { AppDispatch, RootState } from './store';
import { UserApi } from './userApi';

const initialState: {
  user: User;
  authenticated: boolean;
} = {
  user: {
    user_id: '',
    user_name: '',
    mail_address: '',
  } as User,
  authenticated: false,
};

export const signin = createAsyncThunk<
  User,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('user/signin', async (params, { rejectWithValue, dispatch }) => {
  try {

    try {
      await Auth.currentSession()
    }
    catch (err) {
      //nop
    }

    const credentials = await Auth.federatedSignIn({
      customState: params,
      customProvider: 'COGNITO',
    });

    if (credentials.authenticated) {
      const authUser = (await Auth.currentAuthenticatedUser()) as { attributes: { [key: string]: string } };
      setUserId(authUser.attributes['custom:neps2_id']);
      const user = await UserApi.searchUser();
      return user;
    } else {
      return { ...initialState.user };
    }
  } catch (err) {
    return { ...initialState.user };
  }
});

export const signout = createAsyncThunk<
  void,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('user/signout', async (_, { dispatch }) => {
  try {
 
    try {
      await Auth.currentSession()
    }
    catch (err) {
      //nop
    }

    await Auth.signOut();
  } catch (err) {
    console.error(err);
    dispatch(setMessage({ type: 'error', message: 'サインアウトに失敗しました。' }));
  }
});

export const token = createAsyncThunk<
  User,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('user/token', async (_, { rejectWithValue, dispatch }) => {
  try {

    try {
      await Auth.currentSession()
    }
    catch (err) {
      //nop
    }

    const authUser = (await Auth.currentAuthenticatedUser()) as { attributes: { [key: string]: string } };
    setUserId(authUser.attributes['custom:neps2_id']);
    const user = await UserApi.searchUser();
    return user;
  } catch (err) {
    console.log(err);
    return { ...initialState.user };
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signin: (state, action: PayloadAction<User>) => {
    //   state.user = action.payload;
    //   state.authenticated = !!action.payload;
    // },
    // signout: state => {
    //   keycloak.logout().then(() => navigate('/'));
    //   state.user = null;
    //   state.authenticated = false;
    // },
    // setPenMode: (state, action)
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(signin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authenticated = !!action.payload.user_id;
      })
      .addCase(signout.fulfilled, state => {
        state.user = { ...initialState.user };
        state.authenticated = false;
      })
      .addCase(token.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authenticated = !!action.payload.user_id;
      })
      .addMatcher(isRejected(signin, signout), state => {
        state.user = { ...initialState.user };
        state.authenticated = false;
      });
  },
});

// export const { signout } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectGroups = (state: RootState) => state.group.groups;
export const selectUser = (state: RootState) => state.user.user;
export const isAuthenticated = (state: RootState) => state.user.authenticated;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default userSlice.reducer;
