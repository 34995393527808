import { Avatar, Box, Button, Container, CssBaseline, SxProps, Theme } from '@mui/material';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import usePwa from 'use-pwa';
import { useAppDispatch } from '../../app/hooks';
import { signin } from '../../app/userSlice';
import { AppFooter } from '../../components/AppFooter';
import { Copyright } from '../../components/Copyright';
import { AppVersion } from '../../versions';
import React from 'react';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    marginTop: theme => theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme => theme.spacing(1),
    maxWidth: '80%',
    width: 512,
    height: 'auto',
  },
  submit: {
    margin: theme => theme.spacing(3, 0, 2),
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export const LoginPage = () => {
  const { isPwa, unregister } = usePwa();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const login = () => {
    dispatch(signin(new URLSearchParams(location.search).get('token') || ''));
  };

  const handleClick = useCallback(() => {
    (async () => {
      await unregister();
      window.location.reload();
    })();
  }, [unregister]);

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          <Avatar alt="シン・NEPS2" src="icon-512x512.png" sx={sxProps.avatar} />
          <Button type="button" variant="contained" color="primary" sx={sxProps.submit} onClick={login}>
            サインイン
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Copyright />
        </Box>
        {isPwa && (
          <Box sx={sxProps.paper}>
            <Box>{`動作がおかしい場合、以下のボタンを押してください。アプリを強制的に更新します。`}</Box>
            <Box sx={{ mt: 2 }}>{`現在のバージョン${AppVersion}`}</Box>
            <Button variant="contained" color="primary" onClick={handleClick}>
              アプリ強制更新
            </Button>
          </Box>
        )}
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
