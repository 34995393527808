import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import usePwa from 'use-pwa';
import { promptEvent, installed, setInstalled } from './PwaInstaller';

export const InstallHeader = () => {
  // root.addEventListener('click', install.bind(this));
  // root.addEventListener('touchend', install.bind(this));

  const { isPwa, enabledPwa, showInstallPrompt, canInstallprompt, appinstalled } = usePwa();

  return (
    <>
      {enabledPwa && !isPwa && canInstallprompt && (
        <Button
          fullWidth
          disabled={!canInstallprompt || appinstalled}
          variant="contained"
          color="primary"
          onClick={showInstallPrompt}>
          ホーム画面に追加する
        </Button>
      )}
    </>
  );
};
