import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import messageReducer from './messageSlice';
import summaryReducer from './summarySlice';
import cardReducer from './cardSlice';
import clockReducer from './clockSlice';
import departmentReducer from './departmentSlice';
import sakubanReducer from './sakubanSlice';
import workingReducer from './workingHoursSlice';
import initReducer from './initSlice';
import cognitoReducer from './cognitoSlice';

export const store = configureStore({
  reducer: {
    cognito: cognitoReducer,
    user: userReducer,
    message: messageReducer,
    summary: summaryReducer,
    card: cardReducer,
    clock: clockReducer,
    department: departmentReducer,
    sakuban: sakubanReducer,
    workingHour: workingReducer,
    init: initReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// export function resetStore(): void {
//   store.dispatch(resetConnection());
//   store.dispatch(resetControl());
//   store.dispatch(resetSession());
// }
