/* tslint:disable */
/* eslint-disable */
/**
 * shin-neps2-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * カードユーザ登録情報
 * @export
 * @interface CardsPostRequest
 */
export interface CardsPostRequest {
    /**
     * カード登録トークン
     * @type {string}
     * @memberof CardsPostRequest
     */
    token: string;
}
/**
 * カード登録情報
 * @export
 * @interface CardsPresignedPostPostRequest
 */
export interface CardsPresignedPostPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof CardsPresignedPostPostRequest
     */
    idm: string;
}
/**
 * カード登録一次URL
 * @export
 * @interface CardsPresignedPostResponse
 */
export interface CardsPresignedPostResponse {
    /**
     * カード登録署名付きURL 有効期限1日、署名には有効期限、Token、IDmを含めてハッシュにした結果を設定
     * @type {string}
     * @memberof CardsPresignedPostResponse
     */
    url: string;
}
/**
 * 出退勤日
 * @export
 * @interface ClocksClearPostRequest
 */
export interface ClocksClearPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksClearPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksClearPostRequest
     */
    user_id?: string;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksClearPostRequest
     */
    clear_date: string;
}
/**
 * クリア成功
 * @export
 * @interface ClocksClearPostResponse
 */
export interface ClocksClearPostResponse {
    /**
     * 
     * @type {string}
     * @memberof ClocksClearPostResponse
     */
    message?: string;
}
/**
 * 勤怠登録情報
 * @export
 * @interface ClocksGetResponse
 */
export interface ClocksGetResponse {
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksGetResponse
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof ClocksGetResponse
     */
    user_name: string;
    /**
     * 管理職判定
     * @type {boolean}
     * @memberof ClocksGetResponse
     */
    super_visor?: boolean;
    /**
     * 36協定超過作業時間
     * @type {number}
     * @memberof ClocksGetResponse
     */
    agreements36_excess_time_total?: number;
    /**
     * 作番不要
     * @type {boolean}
     * @memberof ClocksGetResponse
     */
    required_work?: boolean;
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksGetResponse
     */
    working_hours_id?: number;
    /**
     * 勤怠初期化済
     * @type {boolean}
     * @memberof ClocksGetResponse
     */
    created?: boolean;
    /**
     * 
     * @type {Array<ClocksPunchItem>}
     * @memberof ClocksGetResponse
     */
    punch_items: Array<ClocksPunchItem>;
}
/**
 * 始業時刻登録情報 勤怠テーブル登録がされていない場合、前月と同内容で作成。 メールで勤怠テーブルを登録したことを通知
 * @export
 * @interface ClocksInPostRequest
 */
export interface ClocksInPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksInPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksInPostRequest
     */
    user_id?: string;
    /**
     * 作番コード
     * @type {number}
     * @memberof ClocksInPostRequest
     */
    sakuban_code?: number;
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksInPostRequest
     */
    working_hours_id?: number;
    /**
     * シフトコード
     * @type {number}
     * @memberof ClocksInPostRequest
     */
    shift_code?: number;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksInPostRequest
     */
    punch_date?: string;
    /**
     * 始業時刻
     * @type {string}
     * @memberof ClocksInPostRequest
     */
    start_time: string;
}
/**
 * 始業時間登録結果
 * @export
 * @interface ClocksInPostResponse
 */
export interface ClocksInPostResponse {
    /**
     * 始業時刻
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    start_time: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    user_name: string;
    /**
     * 管理職判定
     * @type {boolean}
     * @memberof ClocksInPostResponse
     */
    super_visor?: boolean;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    punch_date: string;
    /**
     * 日付種類 1平日 2休日 3法定休日
     * @type {number}
     * @memberof ClocksInPostResponse
     */
    day_type: number;
    /**
     * 届出種類
     * @type {number}
     * @memberof ClocksInPostResponse
     */
    n1_notification_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksInPostResponse
     */
    n1_vacation_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksInPostResponse
     */
    n1_flag?: number;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksInPostResponse
     */
    n2_flag?: number;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    remarks?: string;
    /**
     * 定時始業時刻
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    prescribed_starting_time?: string;
    /**
     * 定時終業時刻
     * @type {string}
     * @memberof ClocksInPostResponse
     */
    prescribed_finishing_time?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClocksInPostResponse
     */
    service_types?: Array<number>;
}
/**
 * 勤怠初期化シフト情報
 * @export
 * @interface ClocksInitItem
 */
export interface ClocksInitItem {
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksInitItem
     */
    working_hours_id: number;
    /**
     * シフトコード
     * @type {number}
     * @memberof ClocksInitItem
     */
    shift_code: number;
    /**
     * 休日区分
     * @type {number}
     * @memberof ClocksInitItem
     */
    holiday_class: number;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksInitItem
     */
    n2_flag?: number;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksInitItem
     */
    punch_date: string;
}
/**
 * 勤怠初期化月
 * @export
 * @interface ClocksInitPostRequest
 */
export interface ClocksInitPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksInitPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksInitPostRequest
     */
    user_id?: string;
    /**
     * 作番コード
     * @type {number}
     * @memberof ClocksInitPostRequest
     */
    sakuban_code: number;
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksInitPostRequest
     */
    working_hours_id: number;
    /**
     * 勤怠登録月 YYYY-MM
     * @type {string}
     * @memberof ClocksInitPostRequest
     */
    punch_month: string;
    /**
     * 勤怠を定時で初期登録
     * @type {boolean}
     * @memberof ClocksInitPostRequest
     */
    preset: boolean;
    /**
     * 
     * @type {Array<ClocksInitItem>}
     * @memberof ClocksInitPostRequest
     */
    init_items?: Array<ClocksInitItem>;
}
/**
 * 勤怠登録情報
 * @export
 * @interface ClocksInitPostResponse
 */
export interface ClocksInitPostResponse {
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksInitPostResponse
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof ClocksInitPostResponse
     */
    user_name: string;
    /**
     * 管理職判定
     * @type {boolean}
     * @memberof ClocksInitPostResponse
     */
    super_visor?: boolean;
    /**
     * 36協定超過作業時間
     * @type {number}
     * @memberof ClocksInitPostResponse
     */
    agreements36_excess_time_total?: number;
    /**
     * 
     * @type {Array<ClocksPunchItem>}
     * @memberof ClocksInitPostResponse
     */
    punch_items: Array<ClocksPunchItem>;
}
/**
 * 終業時刻登録情報 勤怠テーブル登録がされていない場合、前月と同内容で作成。 メールで勤怠テーブルを登録したことを通知
 * @export
 * @interface ClocksOutPostRequest
 */
export interface ClocksOutPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksOutPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksOutPostRequest
     */
    user_id?: string;
    /**
     * 作番コード
     * @type {number}
     * @memberof ClocksOutPostRequest
     */
    sakuban_code?: number;
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksOutPostRequest
     */
    working_hours_id?: number;
    /**
     * シフトコード
     * @type {number}
     * @memberof ClocksOutPostRequest
     */
    shift_code?: number;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksOutPostRequest
     */
    punch_date?: string;
    /**
     * 終業時刻
     * @type {string}
     * @memberof ClocksOutPostRequest
     */
    end_time: string;
}
/**
 * 終業時間登録結果
 * @export
 * @interface ClocksOutPostResponse
 */
export interface ClocksOutPostResponse {
    /**
     * 始業時刻
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    start_time: string;
    /**
     * 終業時刻
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    end_time: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    user_name: string;
    /**
     * 管理職判定
     * @type {boolean}
     * @memberof ClocksOutPostResponse
     */
    super_visor?: boolean;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    punch_date: string;
    /**
     * 休憩時間（分）
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    break_time: number;
    /**
     * 深夜休憩時間（分）
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    late_break_time: number;
    /**
     * 日付種類 1平日 2休日 3法定休日
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    day_type: number;
    /**
     * 勤務時間
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    scheduled_working_hours?: number;
    /**
     * 残業時間
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    overtime_working_hours?: number;
    /**
     * 届出種類
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    n1_notification_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    n1_vacation_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    n1_flag?: number;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksOutPostResponse
     */
    n2_flag?: number;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    remarks?: string;
    /**
     * 定時始業時刻
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    prescribed_starting_time?: string;
    /**
     * 定時終業時刻
     * @type {string}
     * @memberof ClocksOutPostResponse
     */
    prescribed_finishing_time?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClocksOutPostResponse
     */
    service_types?: Array<number>;
}
/**
 * 勤怠登録情報一日分
 * @export
 * @interface ClocksPunchItem
 */
export interface ClocksPunchItem {
    /**
     * シフトコード
     * @type {number}
     * @memberof ClocksPunchItem
     */
    shift_code?: number;
    /**
     * 始業時刻
     * @type {string}
     * @memberof ClocksPunchItem
     */
    start_time?: string;
    /**
     * 終業時刻
     * @type {string}
     * @memberof ClocksPunchItem
     */
    end_time?: string;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksPunchItem
     */
    punch_date: string;
    /**
     * 休憩時間（分）
     * @type {number}
     * @memberof ClocksPunchItem
     */
    break_time?: number;
    /**
     * 深夜休憩時間（分）
     * @type {number}
     * @memberof ClocksPunchItem
     */
    late_break_time?: number;
    /**
     * 日付種類 1平日 2休日 3法定休日
     * @type {number}
     * @memberof ClocksPunchItem
     */
    day_type?: number;
    /**
     * 勤務時間
     * @type {number}
     * @memberof ClocksPunchItem
     */
    scheduled_working_hours?: number;
    /**
     * 残業時間
     * @type {number}
     * @memberof ClocksPunchItem
     */
    overtime_working_hours?: number;
    /**
     * 届出種類
     * @type {number}
     * @memberof ClocksPunchItem
     */
    n1_notification_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksPunchItem
     */
    n1_vacation_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksPunchItem
     */
    n1_flag?: number;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksPunchItem
     */
    n2_flag?: number;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksPunchItem
     */
    remarks?: string;
    /**
     * 定時始業時刻
     * @type {string}
     * @memberof ClocksPunchItem
     */
    prescribed_starting_time?: string;
    /**
     * 定時終業時刻
     * @type {string}
     * @memberof ClocksPunchItem
     */
    prescribed_finishing_time?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClocksPunchItem
     */
    service_types?: Array<number>;
}
/**
 * 出退勤時刻登録情報 勤怠テーブル登録がされていない場合、前月と同内容で作成。 メールで勤怠テーブルを登録したことを通知
 * @export
 * @interface ClocksPunchPostRequest
 */
export interface ClocksPunchPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksPunchPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksPunchPostRequest
     */
    user_id?: string;
    /**
     * 作番コード
     * @type {number}
     * @memberof ClocksPunchPostRequest
     */
    sakuban_code?: number;
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof ClocksPunchPostRequest
     */
    working_hours_id?: number;
    /**
     * シフトコード
     * @type {number}
     * @memberof ClocksPunchPostRequest
     */
    shift_code?: number;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksPunchPostRequest
     */
    punch_date?: string;
    /**
     * 出退勤時刻
     * @type {string}
     * @memberof ClocksPunchPostRequest
     */
    punch_time: string;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksPunchPostRequest
     */
    remarks?: string;
}
/**
 * 出退勤時間登録結果
 * @export
 * @interface ClocksPunchPostResponse
 */
export interface ClocksPunchPostResponse {
    /**
     * 始業時刻
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    start_time?: string;
    /**
     * 終業時刻
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    end_time?: string;
    /**
     * 出勤登録
     * @type {boolean}
     * @memberof ClocksPunchPostResponse
     */
    clocks_in?: boolean;
    /**
     * 退勤登録
     * @type {boolean}
     * @memberof ClocksPunchPostResponse
     */
    clocks_out?: boolean;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    user_name: string;
    /**
     * 管理職判定
     * @type {boolean}
     * @memberof ClocksPunchPostResponse
     */
    super_visor?: boolean;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    punch_date: string;
    /**
     * 休憩時間（分）
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    break_time?: number;
    /**
     * 深夜休憩時間（分）
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    late_break_time?: number;
    /**
     * 日付種類 1平日 2休日 3法定休日
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    day_type: number;
    /**
     * 勤務時間
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    scheduled_working_hours?: number;
    /**
     * 残業時間
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    overtime_working_hours?: number;
    /**
     * 届出種類
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    n1_notification_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    n1_vacation_type?: number;
    /**
     * 休暇種類
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    n1_flag?: number;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksPunchPostResponse
     */
    n2_flag?: number;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    remarks?: string;
    /**
     * 定時始業時刻
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    prescribed_starting_time?: string;
    /**
     * 定時終業時刻
     * @type {string}
     * @memberof ClocksPunchPostResponse
     */
    prescribed_finishing_time?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClocksPunchPostResponse
     */
    service_types?: Array<number>;
}
/**
 * 出退勤時刻登録情報 勤怠テーブル登録がされていない場合、前月と同内容で作成。 メールで勤怠テーブルを登録したことを通知
 * @export
 * @interface ClocksRemarksPostRequest
 */
export interface ClocksRemarksPostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksRemarksPostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksRemarksPostRequest
     */
    user_id?: string;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksRemarksPostRequest
     */
    punch_date?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ClocksRemarksPostRequest
     */
    remarks?: string;
}
/**
 * 登録成功
 * @export
 * @interface ClocksRemarksPostResponse
 */
export interface ClocksRemarksPostResponse {
    /**
     * 
     * @type {string}
     * @memberof ClocksRemarksPostResponse
     */
    message?: string;
}
/**
 * 勤務フラグ登録情報 勤怠テーブル登録がされていない場合、前月と同内容で作成。 メールで勤怠テーブルを登録したことを通知
 * @export
 * @interface ClocksServiceTypePostRequest
 */
export interface ClocksServiceTypePostRequest {
    /**
     * Felica IDm
     * @type {string}
     * @memberof ClocksServiceTypePostRequest
     */
    idm?: string;
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof ClocksServiceTypePostRequest
     */
    user_id?: string;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof ClocksServiceTypePostRequest
     */
    punch_date?: string;
    /**
     * 勤務形式
     * @type {number}
     * @memberof ClocksServiceTypePostRequest
     */
    service_type?: number;
}
/**
 * 登録成功
 * @export
 * @interface ClocksServiceTypePostResponse
 */
export interface ClocksServiceTypePostResponse {
    /**
     * 
     * @type {string}
     * @memberof ClocksServiceTypePostResponse
     */
    message?: string;
}
/**
 * 組織
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 組織ID
     * @type {number}
     * @memberof Department
     */
    department_id: number;
    /**
     * 組織名
     * @type {string}
     * @memberof Department
     */
    department_name: string;
}
/**
 * サーバーの状態のレスポンス
 * @export
 * @interface GetHealthResponse
 */
export interface GetHealthResponse {
    /**
     * 
     * @type {string}
     * @memberof GetHealthResponse
     */
    status: GetHealthResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetHealthResponseStatusEnum {
    Ok = 'ok'
}

/**
 * エラー情報
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * エラーID
     * @type {string}
     * @memberof ModelError
     */
    error_id: string;
    /**
     * エラーメッセージ（日本語）
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * 作番コード
 * @export
 * @interface Sakuban
 */
export interface Sakuban {
    /**
     * 作番コード
     * @type {number}
     * @memberof Sakuban
     */
    sakuban_cd: number;
    /**
     * 作番名
     * @type {string}
     * @memberof Sakuban
     */
    sakuban_name: string;
    /**
     * 組織ID
     * @type {number}
     * @memberof Sakuban
     */
    department_id: number;
}
/**
 * 勤怠登録情報
 * @export
 * @interface SummaryClock
 */
export interface SummaryClock {
    /**
     * 社員番号
     * @type {string}
     * @memberof SummaryClock
     */
    employe_id?: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof SummaryClock
     */
    employe_name?: string;
    /**
     * 出勤日数
     * @type {number}
     * @memberof SummaryClock
     */
    work_days?: number;
    /**
     * 勤怠登録日付 YYYY-MM-DD
     * @type {string}
     * @memberof SummaryClock
     */
    punch_date?: string;
    /**
     * 始業時刻
     * @type {string}
     * @memberof SummaryClock
     */
    start_Time?: string;
    /**
     * 終業時刻
     * @type {string}
     * @memberof SummaryClock
     */
    end_Time?: string;
    /**
     * 勤務時間
     * @type {number}
     * @memberof SummaryClock
     */
    scheduled_working_hours_total?: number;
    /**
     * 残業時間
     * @type {number}
     * @memberof SummaryClock
     */
    overtime_working_hours_total?: number;
    /**
     * チーム識別子
     * @type {string}
     * @memberof SummaryClock
     */
    team_id?: string;
    /**
     * チーム名
     * @type {string}
     * @memberof SummaryClock
     */
    team_name?: string;
    /**
     * 社員番号
     * @type {string}
     * @memberof SummaryClock
     */
    leader_id?: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof SummaryClock
     */
    leader_name?: string;
    /**
     * 社員番号
     * @type {string}
     * @memberof SummaryClock
     */
    manager_id?: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof SummaryClock
     */
    manager_name?: string;
}
/**
 * ユーザー
 * @export
 * @interface User
 */
export interface User {
    /**
     * ユーザー識別子
     * @type {string}
     * @memberof User
     */
    user_id: string;
    /**
     * ユーザ―名
     * @type {string}
     * @memberof User
     */
    user_name: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof User
     */
    mail_address: string;
    /**
     * チームリーダー判定
     * @type {boolean}
     * @memberof User
     */
    team_leader?: boolean;
    /**
     * 営業職判定
     * @type {boolean}
     * @memberof User
     */
    team_manager?: boolean;
    /**
     * 部門長判定
     * @type {boolean}
     * @memberof User
     */
    group_leader?: boolean;
    /**
     * デフォルト勤怠テーブル
     * @type {number}
     * @memberof User
     */
    default_working_hours_id?: number;
    /**
     * デフォルト作番コード
     * @type {number}
     * @memberof User
     */
    default_sakuban_cd?: number;
}
/**
 * 勤怠テーブル
 * @export
 * @interface WorkingHour
 */
export interface WorkingHour {
    /**
     * 勤怠テーブルID
     * @type {number}
     * @memberof WorkingHour
     */
    working_hour_id: number;
    /**
     * 勤怠テーブル名
     * @type {string}
     * @memberof WorkingHour
     */
    working_hour_name: string;
    /**
     * 組織ID
     * @type {number}
     * @memberof WorkingHour
     */
    department_id: number;
    /**
     * 勤務形態
     * @type {number}
     * @memberof WorkingHour
     */
    service_type?: number;
    /**
     * シフトコード
     * @type {number}
     * @memberof WorkingHour
     */
    shift_code: number;
    /**
     * シフト名
     * @type {string}
     * @memberof WorkingHour
     */
    shift_name?: string;
    /**
     * 午前開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    start_am_time?: string;
    /**
     * 午前終了時刻
     * @type {string}
     * @memberof WorkingHour
     */
    end_am_time?: string;
    /**
     * 午後開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    start_pm_time?: string;
    /**
     * 午後終了時刻
     * @type {string}
     * @memberof WorkingHour
     */
    end_pm_time?: string;
    /**
     * 昼休憩時間
     * @type {number}
     * @memberof WorkingHour
     */
    lunch_break_time?: number;
    /**
     * 夕方休憩時間
     * @type {number}
     * @memberof WorkingHour
     */
    at_dusk_break_time?: number;
    /**
     * 残業開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    overtime_start_time?: string;
    /**
     * 残業開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    overtime_count_start_time?: string;
    /**
     * 調整時間
     * @type {number}
     * @memberof WorkingHour
     */
    adjust_time?: number;
    /**
     * 実働時間
     * @type {number}
     * @memberof WorkingHour
     */
    service_time?: number;
}

/**
 * CardsApi - axios parameter creator
 * @export
 */
export const CardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定されたIDmに紐づくユーザ情報を返却
         * @summary カード登録情報取得API
         * @param {string} idm IDm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsIdmGet: async (idm: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'idm' is not null or undefined
            assertParamExists('cardsIdmGet', 'idm', idm)
            const localVarPath = `/cards/{idm}`
                .replace(`{${"idm"}}`, encodeURIComponent(String(idm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定されたIDmにユーザ情報を紐づける
         * @summary カード登録API
         * @param {CardsPostRequest} [cardsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsPost: async (cardsPostRequest?: CardsPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定されたIDmに紐づくユーザ情報を登録するための情報を返却する
         * @summary カード登録URL発行API
         * @param {CardsPresignedPostPostRequest} [cardsPresignedPostPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsPresignedUrlPost: async (cardsPresignedPostPostRequest?: CardsPresignedPostPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cards-presigned-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardsPresignedPostPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardsApi - functional programming interface
 * @export
 */
export const CardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定されたIDmに紐づくユーザ情報を返却
         * @summary カード登録情報取得API
         * @param {string} idm IDm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardsIdmGet(idm: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardsIdmGet(idm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定されたIDmにユーザ情報を紐づける
         * @summary カード登録API
         * @param {CardsPostRequest} [cardsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardsPost(cardsPostRequest?: CardsPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardsPost(cardsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定されたIDmに紐づくユーザ情報を登録するための情報を返却する
         * @summary カード登録URL発行API
         * @param {CardsPresignedPostPostRequest} [cardsPresignedPostPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardsPresignedUrlPost(cardsPresignedPostPostRequest?: CardsPresignedPostPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardsPresignedPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardsPresignedUrlPost(cardsPresignedPostPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardsApi - factory interface
 * @export
 */
export const CardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardsApiFp(configuration)
    return {
        /**
         * 指定されたIDmに紐づくユーザ情報を返却
         * @summary カード登録情報取得API
         * @param {string} idm IDm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsIdmGet(idm: string, options?: any): AxiosPromise<User> {
            return localVarFp.cardsIdmGet(idm, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定されたIDmにユーザ情報を紐づける
         * @summary カード登録API
         * @param {CardsPostRequest} [cardsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsPost(cardsPostRequest?: CardsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.cardsPost(cardsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定されたIDmに紐づくユーザ情報を登録するための情報を返却する
         * @summary カード登録URL発行API
         * @param {CardsPresignedPostPostRequest} [cardsPresignedPostPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardsPresignedUrlPost(cardsPresignedPostPostRequest?: CardsPresignedPostPostRequest, options?: any): AxiosPromise<CardsPresignedPostResponse> {
            return localVarFp.cardsPresignedUrlPost(cardsPresignedPostPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardsApi - object-oriented interface
 * @export
 * @class CardsApi
 * @extends {BaseAPI}
 */
export class CardsApi extends BaseAPI {
    /**
     * 指定されたIDmに紐づくユーザ情報を返却
     * @summary カード登録情報取得API
     * @param {string} idm IDm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public cardsIdmGet(idm: string, options?: any) {
        return CardsApiFp(this.configuration).cardsIdmGet(idm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定されたIDmにユーザ情報を紐づける
     * @summary カード登録API
     * @param {CardsPostRequest} [cardsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public cardsPost(cardsPostRequest?: CardsPostRequest, options?: any) {
        return CardsApiFp(this.configuration).cardsPost(cardsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定されたIDmに紐づくユーザ情報を登録するための情報を返却する
     * @summary カード登録URL発行API
     * @param {CardsPresignedPostPostRequest} [cardsPresignedPostPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardsApi
     */
    public cardsPresignedUrlPost(cardsPresignedPostPostRequest?: CardsPresignedPostPostRequest, options?: any) {
        return CardsApiFp(this.configuration).cardsPresignedUrlPost(cardsPresignedPostPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClocksApi - axios parameter creator
 * @export
 */
export const ClocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定された日の出退勤登録をクリアする
         * @summary 出退勤登録削除API
         * @param {ClocksClearPostRequest} [clocksClearPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksClearPost: async (clocksClearPostRequest?: ClocksClearPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksClearPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された条件で勤怠情報を取得する
         * @summary 勤怠取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [punchDate] 
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksGet: async (idm?: string, userId?: string, punchDate?: string, punchMonth?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idm !== undefined) {
                localVarQueryParameter['idm'] = idm;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (punchDate !== undefined) {
                localVarQueryParameter['punch_date'] = punchDate;
            }

            if (punchMonth !== undefined) {
                localVarQueryParameter['punch_month'] = punchMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された時刻で出勤登録を行う
         * @summary 出勤登録API
         * @param {ClocksInPostRequest} [clocksInPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksInPost: async (clocksInPostRequest?: ClocksInPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksInPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された月の勤怠を初期作成する
         * @summary 勤怠初期化API
         * @param {ClocksInitPostRequest} [clocksInitPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksInitPost: async (clocksInitPostRequest?: ClocksInitPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksInitPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザに紐づく組織での社員の勤怠情報を取得する
         * @summary 勤怠一覧取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [targetDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksListGet: async (idm?: string, userId?: string, targetDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idm !== undefined) {
                localVarQueryParameter['idm'] = idm;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (targetDate !== undefined) {
                localVarQueryParameter['target_date'] = targetDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された時刻で退勤登録を行う
         * @summary 退勤登録API
         * @param {ClocksOutPostRequest} [clocksOutPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksOutPost: async (clocksOutPostRequest?: ClocksOutPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/out`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksOutPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された時刻で出退勤登録を行う
         * @summary 出退勤登録API
         * @param {ClocksPunchPostRequest} [clocksPunchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksPunchPost: async (clocksPunchPostRequest?: ClocksPunchPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/punch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksPunchPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された日の備考を登録する
         * @summary 出退勤備考登録API
         * @param {ClocksRemarksPostRequest} [clocksRemarksPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksRemarksPost: async (clocksRemarksPostRequest?: ClocksRemarksPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/remarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksRemarksPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定された日の勤務フラグを登録する
         * @summary 勤務フラグ登録API
         * @param {ClocksServiceTypePostRequest} [clocksServiceTypePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksServiceTypePost: async (clocksServiceTypePostRequest?: ClocksServiceTypePostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clocks/service_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clocksServiceTypePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClocksApi - functional programming interface
 * @export
 */
export const ClocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定された日の出退勤登録をクリアする
         * @summary 出退勤登録削除API
         * @param {ClocksClearPostRequest} [clocksClearPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksClearPost(clocksClearPostRequest?: ClocksClearPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksClearPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksClearPost(clocksClearPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された条件で勤怠情報を取得する
         * @summary 勤怠取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [punchDate] 
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksGet(idm?: string, userId?: string, punchDate?: string, punchMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksGet(idm, userId, punchDate, punchMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された時刻で出勤登録を行う
         * @summary 出勤登録API
         * @param {ClocksInPostRequest} [clocksInPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksInPost(clocksInPostRequest?: ClocksInPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksInPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksInPost(clocksInPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された月の勤怠を初期作成する
         * @summary 勤怠初期化API
         * @param {ClocksInitPostRequest} [clocksInitPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksInitPost(clocksInitPostRequest?: ClocksInitPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksInitPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksInitPost(clocksInitPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザに紐づく組織での社員の勤怠情報を取得する
         * @summary 勤怠一覧取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [targetDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksListGet(idm?: string, userId?: string, targetDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SummaryClock>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksListGet(idm, userId, targetDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された時刻で退勤登録を行う
         * @summary 退勤登録API
         * @param {ClocksOutPostRequest} [clocksOutPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksOutPost(clocksOutPostRequest?: ClocksOutPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksOutPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksOutPost(clocksOutPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された時刻で出退勤登録を行う
         * @summary 出退勤登録API
         * @param {ClocksPunchPostRequest} [clocksPunchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksPunchPost(clocksPunchPostRequest?: ClocksPunchPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksPunchPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksPunchPost(clocksPunchPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された日の備考を登録する
         * @summary 出退勤備考登録API
         * @param {ClocksRemarksPostRequest} [clocksRemarksPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksRemarksPost(clocksRemarksPostRequest?: ClocksRemarksPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksRemarksPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksRemarksPost(clocksRemarksPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定された日の勤務フラグを登録する
         * @summary 勤務フラグ登録API
         * @param {ClocksServiceTypePostRequest} [clocksServiceTypePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clocksServiceTypePost(clocksServiceTypePostRequest?: ClocksServiceTypePostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClocksServiceTypePostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clocksServiceTypePost(clocksServiceTypePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClocksApi - factory interface
 * @export
 */
export const ClocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClocksApiFp(configuration)
    return {
        /**
         * 指定された日の出退勤登録をクリアする
         * @summary 出退勤登録削除API
         * @param {ClocksClearPostRequest} [clocksClearPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksClearPost(clocksClearPostRequest?: ClocksClearPostRequest, options?: any): AxiosPromise<ClocksClearPostResponse> {
            return localVarFp.clocksClearPost(clocksClearPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された条件で勤怠情報を取得する
         * @summary 勤怠取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [punchDate] 
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksGet(idm?: string, userId?: string, punchDate?: string, punchMonth?: string, options?: any): AxiosPromise<ClocksGetResponse> {
            return localVarFp.clocksGet(idm, userId, punchDate, punchMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された時刻で出勤登録を行う
         * @summary 出勤登録API
         * @param {ClocksInPostRequest} [clocksInPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksInPost(clocksInPostRequest?: ClocksInPostRequest, options?: any): AxiosPromise<ClocksInPostResponse> {
            return localVarFp.clocksInPost(clocksInPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された月の勤怠を初期作成する
         * @summary 勤怠初期化API
         * @param {ClocksInitPostRequest} [clocksInitPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksInitPost(clocksInitPostRequest?: ClocksInitPostRequest, options?: any): AxiosPromise<ClocksInitPostResponse> {
            return localVarFp.clocksInitPost(clocksInitPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザに紐づく組織での社員の勤怠情報を取得する
         * @summary 勤怠一覧取得API
         * @param {string} [idm] 
         * @param {string} [userId] 
         * @param {string} [targetDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksListGet(idm?: string, userId?: string, targetDate?: string, options?: any): AxiosPromise<Array<SummaryClock>> {
            return localVarFp.clocksListGet(idm, userId, targetDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された時刻で退勤登録を行う
         * @summary 退勤登録API
         * @param {ClocksOutPostRequest} [clocksOutPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksOutPost(clocksOutPostRequest?: ClocksOutPostRequest, options?: any): AxiosPromise<ClocksOutPostResponse> {
            return localVarFp.clocksOutPost(clocksOutPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された時刻で出退勤登録を行う
         * @summary 出退勤登録API
         * @param {ClocksPunchPostRequest} [clocksPunchPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksPunchPost(clocksPunchPostRequest?: ClocksPunchPostRequest, options?: any): AxiosPromise<ClocksPunchPostResponse> {
            return localVarFp.clocksPunchPost(clocksPunchPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された日の備考を登録する
         * @summary 出退勤備考登録API
         * @param {ClocksRemarksPostRequest} [clocksRemarksPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksRemarksPost(clocksRemarksPostRequest?: ClocksRemarksPostRequest, options?: any): AxiosPromise<ClocksRemarksPostResponse> {
            return localVarFp.clocksRemarksPost(clocksRemarksPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定された日の勤務フラグを登録する
         * @summary 勤務フラグ登録API
         * @param {ClocksServiceTypePostRequest} [clocksServiceTypePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clocksServiceTypePost(clocksServiceTypePostRequest?: ClocksServiceTypePostRequest, options?: any): AxiosPromise<ClocksServiceTypePostResponse> {
            return localVarFp.clocksServiceTypePost(clocksServiceTypePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClocksApi - object-oriented interface
 * @export
 * @class ClocksApi
 * @extends {BaseAPI}
 */
export class ClocksApi extends BaseAPI {
    /**
     * 指定された日の出退勤登録をクリアする
     * @summary 出退勤登録削除API
     * @param {ClocksClearPostRequest} [clocksClearPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksClearPost(clocksClearPostRequest?: ClocksClearPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksClearPost(clocksClearPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された条件で勤怠情報を取得する
     * @summary 勤怠取得API
     * @param {string} [idm] 
     * @param {string} [userId] 
     * @param {string} [punchDate] 
     * @param {string} [punchMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksGet(idm?: string, userId?: string, punchDate?: string, punchMonth?: string, options?: any) {
        return ClocksApiFp(this.configuration).clocksGet(idm, userId, punchDate, punchMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された時刻で出勤登録を行う
     * @summary 出勤登録API
     * @param {ClocksInPostRequest} [clocksInPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksInPost(clocksInPostRequest?: ClocksInPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksInPost(clocksInPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された月の勤怠を初期作成する
     * @summary 勤怠初期化API
     * @param {ClocksInitPostRequest} [clocksInitPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksInitPost(clocksInitPostRequest?: ClocksInitPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksInitPost(clocksInitPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザに紐づく組織での社員の勤怠情報を取得する
     * @summary 勤怠一覧取得API
     * @param {string} [idm] 
     * @param {string} [userId] 
     * @param {string} [targetDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksListGet(idm?: string, userId?: string, targetDate?: string, options?: any) {
        return ClocksApiFp(this.configuration).clocksListGet(idm, userId, targetDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された時刻で退勤登録を行う
     * @summary 退勤登録API
     * @param {ClocksOutPostRequest} [clocksOutPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksOutPost(clocksOutPostRequest?: ClocksOutPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksOutPost(clocksOutPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された時刻で出退勤登録を行う
     * @summary 出退勤登録API
     * @param {ClocksPunchPostRequest} [clocksPunchPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksPunchPost(clocksPunchPostRequest?: ClocksPunchPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksPunchPost(clocksPunchPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された日の備考を登録する
     * @summary 出退勤備考登録API
     * @param {ClocksRemarksPostRequest} [clocksRemarksPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksRemarksPost(clocksRemarksPostRequest?: ClocksRemarksPostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksRemarksPost(clocksRemarksPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定された日の勤務フラグを登録する
     * @summary 勤務フラグ登録API
     * @param {ClocksServiceTypePostRequest} [clocksServiceTypePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClocksApi
     */
    public clocksServiceTypePost(clocksServiceTypePostRequest?: ClocksServiceTypePostRequest, options?: any) {
        return ClocksApiFp(this.configuration).clocksServiceTypePost(clocksServiceTypePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MasterApi - axios parameter creator
 * @export
 */
export const MasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 承認済表示組織の一覧を取得
         * @summary 組織一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterDepartmentGet: async (punchMonth?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (punchMonth !== undefined) {
                localVarQueryParameter['punch_month'] = punchMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 承認済作番コードの一覧を取得
         * @summary 作番コード一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterSakubanGet: async (punchMonth?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/sakuban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (punchMonth !== undefined) {
                localVarQueryParameter['punch_month'] = punchMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 承認済勤怠テーブルの一覧を取得
         * @summary 勤怠テーブル一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterWorkingHoursGet: async (punchMonth?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/working-hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (punchMonth !== undefined) {
                localVarQueryParameter['punch_month'] = punchMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterApi - functional programming interface
 * @export
 */
export const MasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterApiAxiosParamCreator(configuration)
    return {
        /**
         * 承認済表示組織の一覧を取得
         * @summary 組織一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterDepartmentGet(punchMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Department>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterDepartmentGet(punchMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 承認済作番コードの一覧を取得
         * @summary 作番コード一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterSakubanGet(punchMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sakuban>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterSakubanGet(punchMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 承認済勤怠テーブルの一覧を取得
         * @summary 勤怠テーブル一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async masterWorkingHoursGet(punchMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkingHour>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.masterWorkingHoursGet(punchMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterApi - factory interface
 * @export
 */
export const MasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterApiFp(configuration)
    return {
        /**
         * 承認済表示組織の一覧を取得
         * @summary 組織一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterDepartmentGet(punchMonth?: string, options?: any): AxiosPromise<Array<Department>> {
            return localVarFp.masterDepartmentGet(punchMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 承認済作番コードの一覧を取得
         * @summary 作番コード一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterSakubanGet(punchMonth?: string, options?: any): AxiosPromise<Array<Sakuban>> {
            return localVarFp.masterSakubanGet(punchMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 承認済勤怠テーブルの一覧を取得
         * @summary 勤怠テーブル一覧取得API
         * @param {string} [punchMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        masterWorkingHoursGet(punchMonth?: string, options?: any): AxiosPromise<Array<WorkingHour>> {
            return localVarFp.masterWorkingHoursGet(punchMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterApi - object-oriented interface
 * @export
 * @class MasterApi
 * @extends {BaseAPI}
 */
export class MasterApi extends BaseAPI {
    /**
     * 承認済表示組織の一覧を取得
     * @summary 組織一覧取得API
     * @param {string} [punchMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterDepartmentGet(punchMonth?: string, options?: any) {
        return MasterApiFp(this.configuration).masterDepartmentGet(punchMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 承認済作番コードの一覧を取得
     * @summary 作番コード一覧取得API
     * @param {string} [punchMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterSakubanGet(punchMonth?: string, options?: any) {
        return MasterApiFp(this.configuration).masterSakubanGet(punchMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 承認済勤怠テーブルの一覧を取得
     * @summary 勤怠テーブル一覧取得API
     * @param {string} [punchMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public masterWorkingHoursGet(punchMonth?: string, options?: any) {
        return MasterApiFp(this.configuration).masterWorkingHoursGet(punchMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定されたユーザIDに紐づくユーザ情報を返却
         * @summary ユーザ情報API
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdGet', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定されたユーザIDに紐づくユーザ情報を返却
         * @summary ユーザ情報API
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 指定されたユーザIDに紐づくユーザ情報を返却
         * @summary ユーザ情報API
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 指定されたユーザIDに紐づくユーザ情報を返却
     * @summary ユーザ情報API
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdGet(userId: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


