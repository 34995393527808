import { Dayjs } from 'dayjs';
import { masterApi } from '../components/Api';
import { WorkingHour } from '../generated';

export class WorkingHoursApi {
  public static async searchWorkingHours(targetDate: Dayjs): Promise<WorkingHour[]> {
    const api = await masterApi.masterWorkingHoursGet(targetDate.format('YYYY-MM'));
    const result = await api();
    return result.data;
  }
}
