import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import { CardApi } from './cardApi';
import { AppDispatch, RootState } from './store';

const initialState: {
  status: string;
  loading: boolean;
} = {
  status: '',
  loading: false,
};

export const registCard = createAsyncThunk<
  string,
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('card/registCard', async token => {
  try {
    // const state = getState();
    return await CardApi.registCard(token);
  } catch (err) {
    console.error(err);
    // dispatch(setError('できませんでした。もう一度実行してください。'));
    // return { ...initialState.status };
    return 'error';
  }
});

// export const signout = createAsyncThunk<
//   void,
//   undefined,
//   {
//     state: RootState;
//     dispatch: AppDispatch;
//     rejectValue: string;
//   }
// >('user/signout', async (_, { dispatch }) => {
//   try {
//     await keycloak.logout();
//   } catch (err) {
//     console.error(err);
//     dispatch(setError('サインアウトに失敗しました。'));
//   }
// });

export const cardSlice = createSlice({
  name: 'card',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setSummaryTargetMonth: (state, action: PayloadAction<Dayjs>) => {
    //   state.targetMonth = action.payload.startOf('month').startOf('day');
    // },
    // nextSummaryMonth: state => {
    //   state.targetMonth = state.targetMonth.add(1, 'month').startOf('month').startOf('day');
    // },
    // prevSummaryMonth: state => {
    //   state.targetMonth = state.targetMonth.add(-1, 'month').startOf('month').startOf('day');
    // },
    // signout: state => {
    //   keycloak.logout().then(() => navigate('/'));
    //   state.user = null;
    //   state.authenticated = false;
    // },
    // setPenMode: (state, action)
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(registCard.pending, state => {
        state.loading = true;
      })
      .addCase(registCard.fulfilled, (state, action) => {
        state.status = action.payload;
        state.loading = false;
      })
      .addMatcher(isRejected(registCard), state => {
        state.status = '';
        state.loading = false;
      });
  },
});

// export const { setSummaryTargetMonth, nextSummaryMonth, prevSummaryMonth } = cardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectGroups = (state: RootState) => state.group.groups;
export const selectCardStatus = (state: RootState) => state.card.status;
export const selectCardLoading = (state: RootState) => state.card.loading;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default cardSlice.reducer;
