import { SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@mui/material';
import { orange, red } from '@mui/material/colors';
import dayjs from 'dayjs';
import React from 'react';
import { clockSelectors, ServiceTypes, setTargetDate, VacationDayType, VacationType } from '../../app/clockSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ClocksPunchItem } from '../../generated';

const sxProps: { [name: string]: SxProps<Theme> } = {
  holiday: {
    backgroundColor: orange[200],
  },
  legalHoliday: {
    backgroundColor: red[200],
  },
  header: {
    padding: '8px 6px',
  },
  cell: {
    padding: '8px 6px',
    cursor: 'pointer',
  },
  day: {
    width: 12,
  },
  textCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0,
    textAlign: 'left',
  },
};

export function TimeCalendar() {
  const dispatch = useAppDispatch();
  const records = useAppSelector(state => clockSelectors.selectAll(state));
  const superVisor = useAppSelector(state => state.clock.superVisor);
  const agreement36Time = useAppSelector(state => state.clock.agreement36Time);

  const getRowClass = (record: ClocksPunchItem): SxProps<Theme> => {
    if (record.day_type === 3) {
      return sxProps.legalHoliday;
    } else if (record.day_type === 2) {
      return sxProps.holiday;
    } else {
      const flag = record.n1_flag || record.n1_vacation_type;

      if (!flag) return {};
      if (!VacationType[flag]) return {};
      if (VacationType[flag].dayType === VacationDayType.ONE_DAY) {
        return sxProps.holiday;
      }

      return {};
    }
  };

  const clickDate = (targetDate: dayjs.Dayjs) => {
    dispatch(setTargetDate(targetDate));
    // props.setTargetDate(targetDate);
    window.scrollTo(0, 0);
  };

  const getFlagName = (flag: number | undefined) => {
    if (!flag) return '';
    if (!VacationType[flag]) return flag;
    return VacationType[flag].name;
  };

  const getServiceTypeName = (serviceType: number | undefined) => {
    if (!serviceType) return '';
    return ServiceTypes[serviceType];
  };

  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} align="center" sx={sxProps.header}>
            日付
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 42 }}>
            始業
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 42 }}>
            終業
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 42 }}>
            残業
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 65 }}>
            届出等
          </TableCell>
          <TableCell align="center" sx={sxProps.header}>
            備考
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.length > 0 &&
          records.map(row => {
            const punchDate = dayjs(row.punch_date, 'YYYY-MM-DD');
            const daySxProps = { ...getRowClass(row), ...sxProps.cell, ...sxProps.day } as SxProps<Theme>;
            const cellSxProps = { ...getRowClass(row), ...sxProps.cell } as SxProps<Theme>;
            const textSxProps = { ...getRowClass(row), ...sxProps.cell, ...sxProps.textCell } as SxProps<Theme>;

            return (
              <TableRow key={row.punch_date} onClick={() => clickDate(punchDate)}>
                <TableCell sx={daySxProps} align="center">
                  {punchDate.get('date')}
                </TableCell>
                <TableCell sx={daySxProps} align="center">
                  {punchDate.format('ddd')}
                </TableCell>
                <TableCell sx={cellSxProps} align="center">
                  {row.start_time}
                </TableCell>
                <TableCell sx={cellSxProps} align="center">
                  {row.end_time}
                </TableCell>
                <TableCell sx={cellSxProps} align="center">
                  {row.overtime_working_hours ? `${Math.floor((row.overtime_working_hours * 100) / 60) / 100}` : ''}
                </TableCell>
                <TableCell sx={textSxProps} align="center">
                  <Tooltip
                    title={`${getFlagName(row.n1_flag || row.n1_vacation_type)}${getServiceTypeName(row.n2_flag)}`}
                    disableFocusListener>
                    <span>{getFlagName(row.n1_flag || row.n1_vacation_type) || getServiceTypeName(row.n2_flag)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={textSxProps} align="center">
                  <Tooltip title={row.remarks || ''} disableFocusListener>
                    <span>{row.remarks}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        <TableRow>
          <TableCell colSpan={6} style={{ textAlign: 'right' }}>
            残業合計
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            {Math.floor(
              (records
                .map(r => r.overtime_working_hours || 0)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0) *
                100) /
                60,
            ) / 100}
          </TableCell>
        </TableRow>
        {!superVisor && (
          <TableRow>
            <TableCell colSpan={6} style={{ textAlign: 'right' }}>
              36協定超過作業時間
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>{Math.floor((agreement36Time * 100) / 60) / 100}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
