import { createAsyncThunk, createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { SummaryClock } from '../generated';
import { isErrorInfo } from './apiTypes';
import { setMessage } from './messageSlice';
import { AppDispatch, RootState } from './store';
import { SummaryApi } from './summaryApi';

export type RowType = 'groupManager' | 'teamManager' | 'teamLeader' | 'member';

export type SummaryClockTree = {
  members: SummaryClockTree[];
  row?: SummaryClock;
  old?: string;
  latest?: string;
  sumTime?: number;
  memberCount: number;
  maxTime?: number;
  name: string;
  rowType: RowType;
};

const initialState: {
  targetMonth: Dayjs;
  tree: SummaryClockTree;
  loading: boolean;
} = {
  targetMonth: dayjs().endOf('day'),
  tree: {
    members: [],
    memberCount: 0,
    name: '',
    rowType: 'member',
  },
  loading: false,
};

export const searchSummary = createAsyncThunk<
  SummaryClockTree,
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('summary/searchSummary', async (_, { getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.summary.targetMonth;
    const user = state.user.user;
    return await SummaryApi.createSummaryTree(targetDate, user);
  } catch (err) {
    let message = '検索できませんでした。もう一度実行してください。時間をおいて、再度お試しください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );
    return { ...initialState.tree };
  }
});

// export const signout = createAsyncThunk<
//   void,
//   undefined,
//   {
//     state: RootState;
//     dispatch: AppDispatch;
//     rejectValue: string;
//   }
// >('user/signout', async (_, { dispatch }) => {
//   try {
//     await keycloak.logout();
//   } catch (err) {
//     console.error(err);
//     dispatch(setError('サインアウトに失敗しました。'));
//   }
// });

export const summarySlice = createSlice({
  name: 'summary',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSummaryTargetMonth: (state, action: PayloadAction<Dayjs>) => {
      if (dayjs().get('month') === action.payload.get('month')) {
        state.targetMonth = dayjs().endOf('day');
      } else {
        state.targetMonth = action.payload.endOf('month').endOf('day');
      }
    },
    nextSummaryMonth: state => {
      const nextMonth = state.targetMonth.add(1, 'month');

      if (dayjs().get('month') === nextMonth.get('month')) {
        state.targetMonth = dayjs().endOf('day');
      } else {
        state.targetMonth = nextMonth.endOf('month').endOf('day');
      }
    },
    prevSummaryMonth: state => {
      const prevMonth = state.targetMonth.add(-1, 'month');

      if (dayjs().get('month') === prevMonth.get('month')) {
        state.targetMonth = dayjs().endOf('day');
      } else {
        state.targetMonth = prevMonth.endOf('month').endOf('day');
      }
    },
    // signout: state => {
    //   keycloak.logout().then(() => navigate('/'));
    //   state.user = null;
    //   state.authenticated = false;
    // },
    // setPenMode: (state, action)
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(searchSummary.pending, state => {
        state.loading = true;
      })
      .addCase(searchSummary.fulfilled, (state, action) => {
        state.tree = action.payload;
        state.loading = false;
      })
      .addMatcher(isRejected(searchSummary), state => {
        state.tree = { ...initialState.tree };
        state.loading = false;
      });
  },
});

export const { setSummaryTargetMonth, nextSummaryMonth, prevSummaryMonth } = summarySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectGroups = (state: RootState) => state.group.groups;
export const selectSummaryTargetMonth = (state: RootState) => state.summary.targetMonth;
export const selectSummaryLoading = (state: RootState) => state.summary.loading;
export const selectSummaryTree = (state: RootState) => state.summary.tree;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default summarySlice.reducer;
