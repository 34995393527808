import { createAsyncThunk, createEntityAdapter, createSlice, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { WorkingHour } from '../generated';
import { isErrorInfo } from './apiTypes';
import { setMessage } from './messageSlice';
import { AppDispatch, RootState } from './store';
import { WorkingHoursApi } from './workingHoursApi';

export interface WorkingHourShift {
  /**
   * 勤怠テーブルID
   * @type {number}
   * @memberof WorkingHour
   */
  working_hour_id: number;
  /**
   * 勤怠テーブル名
   * @type {string}
   * @memberof WorkingHour
   */
  working_hour_name: string;
  /**
   * 組織ID
   * @type {number}
   * @memberof WorkingHour
   */
  department_id: number;
  /**
   * 勤務形式
   * @type {number}
   * @memberof ClocksServiceTypePostRequest
   */
  service_type?: number;
  shiftCodes: number[];
  shifts: Shifts;
}

export interface Shifts {
  [shiftCode: number]: {
    /**
     * シフトコード
     * @type {number}
     * @memberof WorkingHour
     */
    shift_code: number;
    /**
     * シフト名
     * @type {string}
     * @memberof WorkingHour
     */
    shift_name?: string;
    /**
     * 午前開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    start_am_time?: string;
    /**
     * 午前終了時刻
     * @type {string}
     * @memberof WorkingHour
     */
    end_am_time?: string;
    /**
     * 午後開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    start_pm_time?: string;
    /**
     * 午後終了時刻
     * @type {string}
     * @memberof WorkingHour
     */
    end_pm_time?: string;
    /**
     * 昼休憩時間
     * @type {number}
     * @memberof WorkingHour
     */
    lunch_break_time?: number;
    /**
     * 夕方休憩時間
     * @type {number}
     * @memberof WorkingHour
     */
    at_dusk_break_time?: number;
    /**
     * 残業開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    overtime_start_time?: string;
    /**
     * 残業開始時刻
     * @type {string}
     * @memberof WorkingHour
     */
    overtime_count_start_time?: string;
    /**
     * 調整時間
     * @type {number}
     * @memberof WorkingHour
     */
    adjust_time?: number;
    /**
     * 実働時間
     * @type {number}
     * @memberof WorkingHour
     */
    service_time?: number;
  };
}

export const workingHoursAdapter = createEntityAdapter<WorkingHourShift>({
  selectId: (workingHour: WorkingHourShift) => workingHour.working_hour_id,
  // 作成日時について降順とする
  sortComparer: (a, b) => a.working_hour_id - b.working_hour_id,
});

const initialState = workingHoursAdapter.getInitialState();

/** データ取得非同期処理 */
export const findWorkingHours = createAsyncThunk<
  WorkingHourShift[],
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('workingHours/findWorkingHours', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const records = await WorkingHoursApi.searchWorkingHours(targetDate);

    const result: { [id: number]: WorkingHourShift } = {};
    const ids: number[] = [];

    for (const record of records) {
      if (!result[record.working_hour_id]) {
        ids.push(record.working_hour_id);
        const shifts: Shifts = {};
        shifts[record.shift_code] = {
          shift_code: record.shift_code,
          shift_name: record.shift_name,
          adjust_time: record.adjust_time,
          at_dusk_break_time: record.at_dusk_break_time,
          end_am_time: record.end_am_time,
          end_pm_time: record.end_pm_time,
          lunch_break_time: record.lunch_break_time,
          overtime_count_start_time: record.overtime_count_start_time,
          overtime_start_time: record.overtime_start_time,
          service_time: record.service_time,
          start_am_time: record.start_am_time,
          start_pm_time: record.start_pm_time,
        };

        result[record.working_hour_id] = {
          working_hour_id: record.working_hour_id,
          working_hour_name: record.working_hour_name,
          department_id: record.department_id,
          service_type: record.service_type,
          shiftCodes: [record.shift_code],
          shifts: shifts,
        };
      } else {
        if (!result[record.working_hour_id].shifts[record.shift_code]) {
          result[record.working_hour_id].shiftCodes.push(record.shift_code);
          result[record.working_hour_id].shifts[record.shift_code] = {
            shift_code: record.shift_code,
            shift_name: record.shift_name,
            adjust_time: record.adjust_time,
            at_dusk_break_time: record.at_dusk_break_time,
            end_am_time: record.end_am_time,
            end_pm_time: record.end_pm_time,
            lunch_break_time: record.lunch_break_time,
            overtime_count_start_time: record.overtime_count_start_time,
            overtime_start_time: record.overtime_start_time,
            service_time: record.service_time,
            start_am_time: record.start_am_time,
            start_pm_time: record.start_pm_time,
          };
        }
      }
    }

    return ids.map(id => result[id]);
  } catch (err) {
    let message = '勤怠情報を取得できませんでした。時間をおいて、再度お試しください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );
    return rejectWithValue('勤怠情報を取得できませんでした。時間をおいて、再度お試しください。');
  }
});

export const workingHourSlice = createSlice({
  name: 'workingHour',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(findWorkingHours.fulfilled, (state, action) => {
        workingHoursAdapter.setAll(state, action.payload);
      })
      .addMatcher(isRejected(findWorkingHours), (state, action) => {
        workingHoursAdapter.removeAll(state);
        //nop
      });
  },
});

// export const {
//   setStartLock,
// } = clockSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const workingHourSelectors = workingHoursAdapter.getSelectors<RootState>(state => state.workingHour);
export const selectWorkingHours = (state: RootState) => workingHourSelectors.selectAll(state);
// export const selectRecords = (state: RootState) => clockSelectors.selectAll(state);
// export const selectMaxDate = (state: RootState) => state.clock.maxDate;
// export const selectTargetDate = (state: RootState) => state.clock.targetDate;
// export const selectStartTime  = (state: RootState) => state.clock.startTime;
// export const selectEndTime  = (state: RootState) => state.clock.endTime;
// export const selectRemarks  = (state: RootState) => state.clock.remarks;
// export const selectServiceType  = (state: RootState) => state.clock.serviceType;
// export const selectShiftCode  = (state: RootState) => state.clock.shiftCode;
// export const selectStartLock =  (state: RootState) => state.clock.startLock;
// export const selectEndLock =  (state: RootState) => state.clock.endLock;
// export const selectCreated =  (state: RootState) => state.clock.created;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default workingHourSlice.reducer;
