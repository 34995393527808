import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { SummaryClockTree } from '../../app/summarySlice';

const rowSxProps: { [name: string]: SxProps<Theme> } = {
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    // marginTop: theme.spacing(4),
    overflow: 'hidden',
  },
  tableHeader: {
    writingMode: 'vertical-rl',
    WebkitWritingMode: 'vertical-lr',
    msWritingMode: 'vertical-lr',
    paddingTop: theme => theme.spacing(2),
    paddingBottom: theme => theme.spacing(2),
    // paddingLeft: 0,
    // paddingRight: 0,
    paddingLeft: 1,
    paddingRight: 1,
    backgroundColor: green[100],
  },
  tableBody: {
    padding: 1,
    // paddingLeft: 0,
    // paddingRight: 0,
    whiteSpace: 'nowrap',
  },
  tableTitle: {
    marginTop: theme => theme.spacing(2),
  },
};

export const GroupManagerTable = (props: { tree: SummaryClockTree; open: boolean }) => {
  const TableRows = (rowProps: { manager: SummaryClockTree; open: boolean }) => {
    const [open, setOpen] = React.useState(props.open);
    useEffect(() => {
      setOpen(rowProps.open);
    }, [rowProps.open]);
    const manager = rowProps.manager;

    return (
      <>
        <TableRow sx={rowSxProps.root}>
          <TableCell sx={{ ...rowSxProps.tableBody, margin: 1 }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            sx={{
              ...rowSxProps.rowSxProps,
              maxWidth: 105,
              width: 105,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {manager.name}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody}>
            {manager.old ? dayjs(manager.old, 'YYYY-MM-DD').format('M/D') : ''}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody}>
            {manager.latest ? dayjs(manager.latest, 'YYYY-MM-DD').format('M/D') : ''}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {manager.memberCount != null && manager.memberCount > 0
              ? Math.floor((((manager.sumTime || 0) / 60 / 60) * 10) / manager.memberCount) / 10
              : 0}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {Math.floor(((manager.maxTime || 0) / 60 / 60) * 10) / 10}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {manager.members.length}
          </TableCell>
        </TableRow>
        {open && (
          <TableRow>
            <TableCell colSpan={7} sx={{ p: '2px' }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box>
                  <TeamManagerTable open={props.open} tree={manager}></TeamManagerTable>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={rowSxProps.table}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={rowSxProps.tableHeader}></TableCell>
              <TableCell sx={rowSxProps.tableHeader}>営業</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>最古登録日</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>最新登録日</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 55 }}>平均残業</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 55 }}>最高残業</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 20 }}>チーム数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tree.members.map((manager, idx) => (
              <TableRows open={props.open} manager={manager} key={idx}></TableRows>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const TeamManagerTable = (props: { tree: SummaryClockTree; open: boolean }) => {
  const TableRows = (rowProps: { manager: SummaryClockTree; open: boolean }) => {
    const [open, setOpen] = React.useState(props.open);
    useEffect(() => {
      setOpen(rowProps.open);
    }, [rowProps.open]);

    const manager = rowProps.manager;

    return (
      <>
        <TableRow sx={rowSxProps.root}>
          <TableCell sx={{ ...rowSxProps.tableBody, margin: 1 }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            sx={{
              ...rowSxProps.tableBody,
              maxWidth: 105,
              width: 105,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {manager.name}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody}>
            {manager.old ? dayjs(manager.old, 'YYYY-MM-DD').format('M/D') : ''}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody}>
            {manager.latest ? dayjs(manager.latest, 'YYYY-MM-DD').format('M/D') : ''}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {manager.memberCount != null && manager.memberCount > 0
              ? Math.floor((((manager.sumTime || 0) / 60 / 60) * 10) / manager.memberCount) / 10
              : 0}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {Math.floor(((manager.maxTime || 0) / 60 / 60) * 10) / 10}
          </TableCell>
          <TableCell sx={rowSxProps.tableBody} align="right">
            {manager.members.length}
          </TableCell>
        </TableRow>
        {open && (
          <TableRow>
            <TableCell colSpan={7} sx={{ p: '2px' }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box>
                  <TeamLeaderTable open={props.open} tree={manager}></TeamLeaderTable>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={rowSxProps.table}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...rowSxProps.tableHeader }}></TableCell>
              <TableCell
                sx={{
                  ...rowSxProps.tableHeader,
                }}>
                チーム
              </TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>最古登録日</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>最新登録日</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 55 }}>平均残業</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 55 }}>最高残業</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader }}>人数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tree.members.map((manager, idx) => (
              <TableRows open={props.open} manager={manager} key={idx}></TableRows>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const TeamLeaderTable = (props: { tree: SummaryClockTree; open: boolean }) => {
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <>
      {/* <Typography variant="h6" gutterBottom component="div" className={classes.tableTitle}>
        {props.tree.row ? props.tree.row.team_name : ''}
      </Typography> */}
      <TableContainer component={Paper} sx={rowSxProps.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={rowSxProps.tableHeader}>名前</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>登録日</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>出勤</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 40 }}>退勤</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 20 }}>出勤日数</TableCell>
              <TableCell sx={{ ...rowSxProps.tableHeader, width: 55 }}>残業時間</TableCell>
              {/* <TableCell className={classes.tableHeader}>平均残業(日)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tree.members.map(manager => {
              if (!manager.row) return '';

              return (
                <TableRow key={manager.row.employe_id} sx={rowSxProps.root}>
                  <TableCell sx={rowSxProps.tableBody}>{manager.row.employe_name}</TableCell>
                  <TableCell sx={rowSxProps.tableBody}>
                    {manager.row.punch_date ? dayjs(manager.row.punch_date, 'YYYY-MM-DD').format('M/D') : ''}
                  </TableCell>
                  <TableCell sx={rowSxProps.tableBody}>{manager.row.start_Time}</TableCell>
                  <TableCell sx={rowSxProps.tableBody}>{manager.row.end_Time}</TableCell>
                  <TableCell sx={rowSxProps.tableBody} align="right">
                    {manager.row.work_days}
                  </TableCell>
                  <TableCell sx={rowSxProps.tableBody} align="right">
                    {manager.row.overtime_working_hours_total != null
                      ? Math.floor(((manager.row.overtime_working_hours_total || 0) / 60 / 60) * 10) / 10
                      : 0}
                  </TableCell>
                  {/* <TableCell className={classes.tableBody} align="right">
                    {manager.row.work_days != null && manager.row.work_days > 0
                      ? Math.floor(
                          (((manager.row.overtime_working_hours_total || 0) / 60 / 60) * 10) / manager.row.work_days,
                        ) / 10
                      : 0}
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const TimeCardTable = (props: { tree: SummaryClockTree; open: boolean }) => {
  return (
    <>
      {props.tree.rowType === 'groupManager' && (
        <GroupManagerTable open={props.open} tree={props.tree}></GroupManagerTable>
      )}
      {props.tree.rowType === 'teamManager' && (
        <TeamManagerTable open={props.open} tree={props.tree}></TeamManagerTable>
      )}
      {props.tree.rowType === 'teamLeader' && <TeamLeaderTable open={props.open} tree={props.tree}></TeamLeaderTable>}
    </>
  );
};
