import { createStore } from 'zustand';
interface CognitoStore {
  userId: string;
  setUserId: (userId: string) => void;
  reset: () => void;
}

export const cognitoStore = createStore<CognitoStore>((set, get, api) => ({
  userId: '',
  reset: () =>
    set({
      userId: '',
    }),
  setUserId: (userId: string) =>
    set({
      userId,
    }),
}));
