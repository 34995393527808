/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  SxProps,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Theme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { clockSelectors, setTargetDate, ServiceTypes } from '../../app/clockSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ClocksInitItem, ClocksPunchItem } from '../../generated';
import React from 'react';
import { selectInitItems, setRecord } from '../../app/initSlice';
import { workingHourSelectors } from '../../app/workingHoursSlice';
import { orange, red } from '@mui/material/colors';

const sxProps: { [name: string]: SxProps<Theme> } = {
  holiday: {
    backgroundColor: orange[200],
  },
  legalHoliday: {
    backgroundColor: red[200],
  },
  header: {
    padding: '8px 6px',
  },
  cell: {
    padding: '8px 6px',
    cursor: 'pointer',
  },
  day: {
    width: 12,
  },
  textCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0,
    textAlign: 'left',
  },
};

export function InitTimeCalendar(props: { selectWorkingHourId: number }) {
  const dispatch = useAppDispatch();
  const initItems = useAppSelector(selectInitItems);
  // const superVisor = useAppSelector(state => state.clock.superVisor);
  // const agreement36Time = useAppSelector(state => state.clock.agreement36Time);
  const workingHour = useAppSelector(state => workingHourSelectors.selectById(state, props.selectWorkingHourId));

  const getRowClass = (record: ClocksInitItem): SxProps<Theme> => {
    if (record.holiday_class === 3) {
      return sxProps.legalHoliday;
    } else if (record.holiday_class === 2) {
      return sxProps.holiday;
    } else {
      return {};
    }
  };

  const getServiceTypeName = (serviceType: number | undefined) => {
    if (!serviceType) return '';
    return ServiceTypes[serviceType];
  };

  const changeShift = (record: ClocksInitItem, shiftCode: number) => {
    dispatch(
      setRecord({
        ...record,
        shift_code: shiftCode,
      }),
    );
  };

  const changeHoliday = (record: ClocksInitItem, holidayClass: number) => {
    dispatch(
      setRecord({
        ...record,
        holiday_class: holidayClass,
      }),
    );
  };

  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} align="center" sx={sxProps.header}>
            日付
          </TableCell>
          {workingHour && workingHour.service_type === 6 && (
            <TableCell align="center" sx={sxProps.header} style={{ width: 55 }}>
              休日
            </TableCell>
          )}
          <TableCell align="center" sx={sxProps.header} style={{ width: 55 }}>
            シフト
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 42 }}>
            始業
          </TableCell>
          <TableCell align="center" sx={sxProps.header} style={{ width: 42 }}>
            終業
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {initItems.map(row => {
          const punchDate = dayjs(row.punch_date, 'YYYY-MM-DD');
          const daySxProps = { ...getRowClass(row), ...sxProps.cell, ...sxProps.day } as SxProps<Theme>;
          const cellSxProps = { ...getRowClass(row), ...sxProps.cell } as SxProps<Theme>;
          // const textSxProps = { ...getRowClass(row), ...sxProps.cell, ...sxProps.textCell };

          return (
            <TableRow key={row.punch_date}>
              <TableCell sx={daySxProps} align="center">
                {punchDate.get('date')}
              </TableCell>
              <TableCell sx={daySxProps} align="center">
                {punchDate.format('ddd')}
              </TableCell>
              {workingHour && workingHour.service_type === 6 && (
                <TableCell sx={cellSxProps} align="center">
                  <Select
                    fullWidth
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 4,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingBottom: 4,
                      },
                    }}
                    IconComponent={Box}
                    disabled={!workingHour}
                    value={String(row.holiday_class)}
                    onChange={(event: SelectChangeEvent) => changeHoliday(row, Number(event.target.value))}
                    size="small">
                    <MenuItem value={'1'}>平日</MenuItem>
                    <MenuItem value={'2'}>休日</MenuItem>
                    <MenuItem value={'3'}>法定休日</MenuItem>
                  </Select>
                </TableCell>
              )}
              <TableCell sx={cellSxProps} align="center">
                {((workingHour && workingHour.service_type === 6 && row.holiday_class === 1) ||
                  (workingHour && workingHour.service_type !== 6)) && (
                  <Select
                    fullWidth
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 4,
                        paddingLeft: 2,
                        paddingRight: 2,
                        paddingBottom: 4,
                      },
                    }}
                    IconComponent={Box}
                    disabled={!workingHour}
                    value={String(row.shift_code)}
                    onChange={(event: SelectChangeEvent) => changeShift(row, Number(event.target.value))}
                    size="small">
                    {workingHour &&
                      workingHour.shiftCodes.map(shiftCode => (
                        <MenuItem key={shiftCode} value={String(shiftCode)}>
                          {workingHour.shifts[shiftCode].shift_name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </TableCell>
              <TableCell sx={cellSxProps} align="center">
                {workingHour && workingHour.shifts[row.shift_code].start_am_time}
              </TableCell>
              <TableCell sx={cellSxProps} align="center">
                {workingHour && workingHour.shifts[row.shift_code].end_pm_time}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
