import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ClocksInitItem, ClocksPunchItem } from '../generated';
import { RootState } from './store';
import { WorkingHourShift } from './workingHoursSlice';

export const initItemAdapter = createEntityAdapter<ClocksInitItem>({
  selectId: (item: ClocksInitItem) => item.punch_date,
  // 作成日時について降順とする
  sortComparer: (a, b) => dayjs(a.punch_date, 'YYYY-MM-DD').unix() - dayjs(b.punch_date, 'YYYY-MM-DD').unix(),
});

const initialState = initItemAdapter.getInitialState({
  //勤怠デフォルト設定
  preset: false,
});

export const initSlice = createSlice({
  name: 'init',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRecords: (state, action: PayloadAction<{ records: ClocksPunchItem[]; workingHour: WorkingHourShift }>) => {
      const { records, workingHour } = action.payload;

      const initItems = records.map(pItem => {
        const item: ClocksInitItem = {
          punch_date: pItem.punch_date,
          holiday_class: pItem.day_type || 1,
          shift_code: workingHour.shiftCodes.length > 0 ? workingHour.shifts[workingHour.shiftCodes[0]].shift_code : -1,
          working_hours_id: workingHour.working_hour_id,
        };
        return item;
      });
      initItemAdapter.setAll(state, initItems);
    },
    setRecord: (state, action: PayloadAction<ClocksInitItem>) => {
      initItemAdapter.updateOne(state, {
        id: action.payload.punch_date,
        changes: {
          n2_flag: action.payload.n2_flag,
          shift_code: action.payload.shift_code,
          working_hours_id: action.payload.working_hours_id,
          holiday_class: action.payload.holiday_class,
        },
      });
    },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: builder => {
  //     builder
  //       .addCase(findDepartments.fulfilled, (state, action) => {
  //         departmentAdapter.setAll(state, action.payload);
  //       })
  //       .addMatcher(isRejected(findDepartments), (state, action) => {
  //         departmentAdapter.removeAll(state);
  //         //nop
  //       });
  //   },
});

export const { setRecord, setRecords } = initSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const initSelectors = initItemAdapter.getSelectors<RootState>(state => state.init);
export const selectInitItems = (state: RootState) => initSelectors.selectAll(state);

// export const selectRecords = (state: RootState) => clockSelectors.selectAll(state);
// export const selectMaxDate = (state: RootState) => state.clock.maxDate;
// export const selectTargetDate = (state: RootState) => state.clock.targetDate;
// export const selectStartTime  = (state: RootState) => state.clock.startTime;
// export const selectEndTime  = (state: RootState) => state.clock.endTime;
// export const selectRemarks  = (state: RootState) => state.clock.remarks;
// export const selectServiceType  = (state: RootState) => state.clock.serviceType;
// export const selectShiftCode  = (state: RootState) => state.clock.shiftCode;
// export const selectStartLock =  (state: RootState) => state.clock.startLock;
// export const selectEndLock =  (state: RootState) => state.clock.endLock;
// export const selectCreated =  (state: RootState) => state.clock.created;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default initSlice.reducer;
