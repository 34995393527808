import DayjsUtils from '@date-io/dayjs';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Amplify, Hub } from 'aws-amplify';
import dayjs from 'dayjs';
import jaLocale from 'dayjs/locale/ja';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { reset, selectMessage } from './app/messageSlice';
import { store } from './app/store';
import { isAuthenticated, token } from './app/userSlice';
import { InstallHeader } from './components/InstallHeader';
import { UpdateHeader } from './components/UpdateHeader';
import { CardPage } from './pages/CardPage';
import { HelpPage } from './pages/HelpPage';
import { InfoPage } from './pages/InfoPage';
import { LoginPage } from './pages/LoginPage';
import { SummaryPage } from './pages/SummaryPage';
import { TimecardPage } from './pages/TimecardPage';
import React from 'react';

class ExtendedUtils extends DayjsUtils {
  getCalendarHeaderText(date: dayjs.Dayjs) {
    return date.format('YYYY年M月');
  }
  getDatePickerHeaderText(date: dayjs.Dayjs) {
    return date.format('M月D日');
  }
}

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
    scope: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

export function App() {
  return (
    <Provider store={store}>
      <AppCore></AppCore>
    </Provider>
  );
}

function AppCore() {
  // const { isPwa, unregister, enabledUpdate } = usePwa();

  // useEffect(() => {
  //   (async () => {
  //     if (isPwa && enabledUpdate) {
  //       window.alert('アプリを更新します。');
  //       const result = await unregister();
  //       window.alert(result);
  //       if (result) {
  //         window.alert('アプリが更新されました。再表示します。');
  //         window.location.reload();
  //         return;
  //       }
  //     }
  //   })();
  // }, [isPwa, unregister, enabledUpdate]);

  // const [authenticated, setAuthenticated] = useState<boolean>(false);
  // const [user, setUser] = useState<User>();
  const authenticated = useAppSelector(isAuthenticated);
  const dispatch = useAppDispatch();
  const message = useAppSelector(selectMessage);
  /**
   * 認証状態の取得
   */
  useEffect(() => {
    dispatch(token());

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          if (data) {
            location.href = `${location.href}/#/`;
          }
      }
    });
  }, []);

  useEffect(() => {
    if (message.message) {
      toast(message.message, {
        type: message.type,
        onClose: () => dispatch(reset()),
      });
    }
  }, [message]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter} utils={ExtendedUtils} locale={jaLocale}>
      <InstallHeader></InstallHeader>
      <UpdateHeader></UpdateHeader>
      <ToastContainer position="top-center"></ToastContainer>
      {!authenticated && (
        <HashRouter>
          <Routes>
            <Route path="help" element={<HelpPage></HelpPage>}></Route>
            <Route path="*" element={<LoginPage></LoginPage>}></Route>
          </Routes>
        </HashRouter>
      )}
      {authenticated && (
        <HashRouter>
          <Routes>
            <Route path="card" element={<CardPage></CardPage>}></Route>
            <Route path="users" element={<SummaryPage></SummaryPage>}></Route>
            <Route path="info" element={<InfoPage></InfoPage>}></Route>
            <Route path="help" element={<HelpPage></HelpPage>}></Route>
            <Route path="*" element={<TimecardPage></TimecardPage>}></Route>
          </Routes>
        </HashRouter>
      )}
    </LocalizationProvider>
  );
}
