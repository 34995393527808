import { createAsyncThunk, createEntityAdapter, createSlice, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { Sakuban } from '../generated';
import { isErrorInfo } from './apiTypes';
import { setMessage } from './messageSlice';
import { SakubanApi } from './sakubanApi';
import { AppDispatch, RootState } from './store';

export const sakubanAdapter = createEntityAdapter<Sakuban>({
  selectId: (sakuban: Sakuban) => sakuban.sakuban_cd,
  // 作成日時について降順とする
  sortComparer: (a, b) => a.sakuban_cd - b.sakuban_cd,
});

const initialState = sakubanAdapter.getInitialState();

/** データ取得非同期処理 */
export const findSakubans = createAsyncThunk<
  Sakuban[],
  undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('sakuban/findSakubans', async (_, { rejectWithValue, getState, dispatch }) => {
  try {
    const state = getState();
    const targetDate = state.clock.targetDate;
    const records = await SakubanApi.searchSakuban(targetDate);
    return records;
  } catch (err) {
    let message = '勤怠情報を取得できませんでした。時間をおいて、再度お試しください。';
    if (axios.isAxiosError(err)) {
      if (err.response && err.response.data && isErrorInfo(err.response.data)) {
        message = err.response.data.message;
      }
    }
    console.error(err);
    dispatch(
      setMessage({
        type: 'error',
        message: message,
      }),
    );
    return rejectWithValue('勤怠情報を取得できませんでした。時間をおいて、再度お試しください。');
  }
});

export const sakubanSlice = createSlice({
  name: 'sakuban',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(findSakubans.fulfilled, (state, action) => {
        sakubanAdapter.setAll(state, action.payload);
      })
      .addMatcher(isRejected(findSakubans), state => {
        sakubanAdapter.removeAll(state);
        //nop
      });
  },
});

// export const {
//   setStartLock,
// } = clockSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const sakubanSelectors = sakubanAdapter.getSelectors<RootState>(state => state.sakuban);
export const selectSakubans = (state: RootState) => sakubanSelectors.selectAll(state);

// export const selectRecords = (state: RootState) => clockSelectors.selectAll(state);
// export const selectMaxDate = (state: RootState) => state.clock.maxDate;
// export const selectTargetDate = (state: RootState) => state.clock.targetDate;
// export const selectStartTime  = (state: RootState) => state.clock.startTime;
// export const selectEndTime  = (state: RootState) => state.clock.endTime;
// export const selectRemarks  = (state: RootState) => state.clock.remarks;
// export const selectServiceType  = (state: RootState) => state.clock.serviceType;
// export const selectShiftCode  = (state: RootState) => state.clock.shiftCode;
// export const selectStartLock =  (state: RootState) => state.clock.startLock;
// export const selectEndLock =  (state: RootState) => state.clock.endLock;
// export const selectCreated =  (state: RootState) => state.clock.created;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default sakubanSlice.reducer;
