import { Dayjs } from 'dayjs';
import { masterApi } from '../components/Api';

export class SakubanApi {
  public static async searchSakuban(targetDate: Dayjs) {
    const api = await masterApi.masterSakubanGet(targetDate.format('YYYY-MM'));
    const result = await api();
    return result.data;
  }
}
