export type ErrorInfo = {
  error_id: string;
  message: string;
};

export function isErrorInfo(arg: unknown): arg is ErrorInfo {
  return (
    typeof arg === 'object' &&
    arg != null &&
    typeof (arg as ErrorInfo).error_id === 'string' &&
    typeof (arg as ErrorInfo).message === 'string' &&
    (arg as ErrorInfo).error_id !== 'E900' &&
    (arg as ErrorInfo).error_id !== 'E999'
  );
}
