import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  nextSummaryMonth,
  prevSummaryMonth,
  searchSummary,
  selectSummaryLoading,
  selectSummaryTargetMonth,
  selectSummaryTree,
  setSummaryTargetMonth,
} from '../../app/summarySlice';
import { AppFooter } from '../../components/AppFooter';
import { TimeCardTable } from './TimeCardTable';

const sxProps: { [name: string]: SxProps<Theme> } = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme => theme.spacing(12),
  },
  title: {
    marginTop: theme => theme.spacing(4),
    marginBottom: theme => theme.spacing(4),
  },
  avatar: {
    margin: theme => theme.spacing(1),
    maxWidth: '80%',
    width: 512,
    height: 'auto',
  },
  switch: {
    marginTop: theme => theme.spacing(2),
    marginBottom: theme => theme.spacing(4),
  },
  footer: {
    paddingLeft: 0,
    paddingRight: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export function SummaryPage() {
  const dispatch = useAppDispatch();
  const targetDate = useAppSelector(selectSummaryTargetMonth);
  const loading = useAppSelector(selectSummaryLoading);
  const tree = useAppSelector(selectSummaryTree);
  const [open, setOpen] = useState<boolean>(false);
  const maxDate = dayjs().startOf('month').startOf('day');

  const selectMonth = (d: Dayjs) => {
    dispatch(setSummaryTargetMonth(d));
  };

  useEffect(() => {
    dispatch(searchSummary());
  }, [targetDate]);

  const prevMonth = () => {
    dispatch(prevSummaryMonth());
  };
  const nextMonth = () => {
    dispatch(nextSummaryMonth());
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mb: '48px' }}>
        <CssBaseline />
        <Box sx={sxProps.paper}>
          <Typography variant="h5" sx={sxProps.title}>
            勤怠登録状況
          </Typography>
          <Box sx={sxProps.switch}>
            <MobileDatePicker
              InputProps={{
                startAdornment: (
                  <IconButton color="primary" size="small" aria-label="prev-month" onClick={prevMonth}>
                    <NavigateBefore />
                  </IconButton>
                ),
                endAdornment: (
                  <>
                    <IconButton
                      disabled={targetDate.startOf('month').startOf('day').isSame(maxDate)}
                      color="primary"
                      size="small"
                      aria-label="next-day"
                      onClick={nextMonth}>
                      <NavigateNext />
                    </IconButton>
                  </>
                ),
                sx: { pl: 0, pr: 0 },
              }}
              disableFuture
              showToolbar={false}
              disableCloseOnSelect={false}
              value={targetDate}
              inputFormat="YYYY年M月"
              openTo="month"
              views={['year', 'month']}
              onChange={d => {
                selectMonth(d);
              }}
              renderInput={params => <TextField size="small" helperText={null} {...params} />}
            />
          </Box>
          {!loading &&
            (tree.rowType === 'groupManager' || tree.rowType === 'teamManager') &&
            tree.members.length > 0 && (
              <Box sx={sxProps.switch}>
                <FormControlLabel
                  control={<Switch checked={open} onChange={(ev, open) => setOpen(open)} color="primary" name="open" />}
                  label="全て開く"></FormControlLabel>
              </Box>
            )}
          {loading && (
            <Box margin={8}>
              <CircularProgress />
            </Box>
          )}
          {!loading && tree && <TimeCardTable open={open} tree={tree}></TimeCardTable>}
        </Box>
      </Container>
      <Container maxWidth="xs" sx={sxProps.footer}>
        <AppFooter></AppFooter>
      </Container>
    </>
  );
}
